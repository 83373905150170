import React from "react";
import {
  Grid,
  Button,
  Stack,
  Avatar,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import TranslateIcon from "@mui/icons-material/Translate";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook

// Import the language
import { supportedLanguages } from "../data/data";

// Import your background image
import backgroundImageLarge from "../images/ParkMap.png";
import backgroundImageSmall from "../images/ParkMap-sm.png";
import logo from "../images/logo.png";

export const FirstLoad = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(); // Initialize the useTranslation hook

  // Check if a language is stored in local storage and set it as the initial language
  React.useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  const renderLanguageButton = (
    language: string,
    name: string,
    flagUrl: string,
    route: string,
    showSimplified: boolean,
    simp: string
  ) => {
    const handleLanguageChange = () => {
      i18n.changeLanguage(language);
      localStorage.setItem("language", language); // Store the selected language in local storage
      navigate(`/${language}${route}`); // Include the language prefix in the route
    };

    return (
      <Grid item key={language}>
        <Paper>
          <Button onClick={handleLanguageChange}>
            <div style={{ position: "relative" }}>
              {showSimplified && (
                <div
                  style={{
                    position: "absolute",
                    top: -6,
                    right: -8,
                    backgroundColor: "green", // Customize the ribbon color
                    padding: "1px 5px", // Customize the ribbon padding
                    color: "yellow", // Customize the ribbon text color
                    zIndex: 1, // Ensure the ribbon appears above the button content
                  }}>
                  <Typography variant='subtitle2'>{simp}</Typography>
                </div>
              )}
              <Stack
                direction='row'
                alignItems='center'
                style={{
                  paddingTop: showSimplified ? "10px" : "0px",
                  padding: isSmallScreen ? "0" : "10px",
                }}>
                <Avatar
                  alt='Logo'
                  src={flagUrl}
                  style={{ maxWidth: 40, maxHeight: 40 }}
                />
                <Typography paddingLeft='10px'>{name}</Typography>
              </Stack>
            </div>
          </Button>
        </Paper>
      </Grid>
    );
  };

  const isSmallScreen = window.innerWidth <= 768;
  const smallHeight = window.innerHeight;
  const backgroundImage = isSmallScreen
    ? backgroundImageSmall
    : backgroundImageLarge;

  // Set the title and description for this page
  document.title = t("parkName");
  const metaDescription = document.createElement("meta");
  metaDescription.name = "description";
  metaDescription.content = t("information.text");
  document.head.appendChild(metaDescription);

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      height={isSmallScreen ? "500px" : "100vh"}
      minHeight={smallHeight}
      style={{
        backgroundColor: "lightgoldenrodyellow",
        backgroundPosition: "center",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
      }}>
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='space-evenly'
        style={{ height: "100%" }}>
        <Grid
          item
          display={"flex"}
          flexDirection={"row"}
          alignItems='center'
          padding={1.5}
          style={{ backgroundColor: "white", borderRadius: "10px" }}>
          <Avatar
            alt='Logo'
            src={logo}
            sx={{
              width: "10vw",
              maxWidth: "150px",
              minWidth: "50px",
              height: "auto",
            }}
          />
          <Typography
            textAlign={"center"}
            variant='h3'
            color={"primary"}
            style={{ textDecoration: "underline" }}>
            El Jardi Dels Ocells
          </Typography>
        </Grid>

        <Grid
          container
          direction='row'
          justifyContent={"space-evenly"}
          spacing={2}>
          {supportedLanguages.map((lang) =>
            renderLanguageButton(
              lang.code,
              lang.name,
              lang.flag,
              lang.route,
              lang.showSimplified,
              lang.simp
            )
          )}
          <Grid item key={"crowdin"}>
            <Paper>
              <Link
                to='https://crowdin.com/project/el-jardi-dels-ocells-simp'
                target='_blank'
                rel='noopener noreferrer'
                style={{ textDecoration: "none", color: "inherit" }}>
                <Button>
                  <Stack direction='row' alignItems='center' padding='10px'>
                    <TranslateIcon />
                    <Typography paddingLeft='10px'>Help us add more</Typography>
                  </Stack>
                </Button>
              </Link>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FirstLoad;
