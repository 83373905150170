import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { Footer } from "../components/Bars/Footer";
import { NavBarTop } from "../components/Bars/NavBarTop";
import { useTranslation } from "react-i18next";
import InteractiveMap from "../components/InteractiveMap";

export const Map = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = t("menu.map");
  });
  return (
    <Grid>
      <Grid container>
        <NavBarTop />
      </Grid>
      <Grid container>
        <Grid item xs={12} marginBottom={"-5px"}>
          <Grid className='pageImg'>
            <div style={{ position: "relative" }}>
              <img
                src={require("../images/headers/turaco.jpg")}
                alt=''
                className='pageImg'
              />
              <div className='pageTitle'>
                <Typography variant='h4' className='boxBorder' padding={1}>
                  {t("menu.map")}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} className='boxYellow' minHeight={"450px"}>
          <InteractiveMap />
        </Grid>
      </Grid>
      <Grid container>
        <Footer />
      </Grid>
    </Grid>
  );
};
