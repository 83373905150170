import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";

import ExpandCircleDownTwoToneIcon from "@mui/icons-material/ExpandCircleDownTwoTone";

export const FaqBuild = () => {
  const { t: translate } = useTranslation();

  const t = (key: string) => {
    const translation = translate(key);
    return translation !== key ? translation : null;
  };

  const generateAccordionPanels = (
    area: string,
    expanded: string | false,
    setExpanded: React.Dispatch<React.SetStateAction<string | false>>
  ) => {
    const panels = [];

    const handleChange =
      (panel: string) =>
      (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
      };
    let i = 1;
    while (true) {
      const panelId = `panel${area}${i}`;
      const qa = `faq.question${area}${i}`;
      const q = t(qa);

      if (!q) {
        break;
      }

      panels.push(
        <Accordion
          key={panelId}
          expanded={expanded === panelId}
          onChange={handleChange(panelId)}>
          <AccordionSummary
            aria-controls={`${panelId}d-content`}
            id={`${panelId}d-header`}>
            <ExpandCircleDownTwoToneIcon />
            <Typography variant='h6'>{t(`faq.question${area}${i}`)}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t(`faq.answer${area}${i}`)}</Typography>
          </AccordionDetails>
        </Accordion>
      );
      i++;
    }

    return panels;
  };

  const [expanded100, setExpanded100] = React.useState<string | false>(
    "panel101"
  );
  const [expanded200, setExpanded200] = React.useState<string | false>(
    "panel201"
  );
  const [expanded300, setExpanded300] = React.useState<string | false>(
    "panel301"
  );

  return (
    <Grid container padding={1}>
      <Grid item md={6} padding={1}>
        <Card>
          <Typography padding={1} variant='h4' align='center'>
            {t("faq.area101")}
          </Typography>
        </Card>
        {generateAccordionPanels("10", expanded100, setExpanded100)}
      </Grid>
      <Grid item md={6} padding={1}>
        <img
          src={require("../images/picnic.jpg")}
          alt='picnic'
          className='mapImg'
        />
      </Grid>
      <Grid
        item
        md={6}
        padding={1}
        sx={{ display: { xs: "none", md: "block", lg: "block" } }}>
        <img
          src={require("../images/pond.jpg")}
          alt='pond'
          className='mapImg'
        />
      </Grid>
      <Grid item md={6} padding={1}>
        <Card>
          <Typography padding={1} variant='h4' align='center'>
            {t("faq.area201")}
          </Typography>
        </Card>
        {generateAccordionPanels("20", expanded200, setExpanded200)}
      </Grid>
      <Grid
        item
        md={6}
        padding={1}
        sx={{ display: { xs: "block", md: "none", lg: "none" } }}>
        <img
          src={require("../images/pond.jpg")}
          alt='pond'
          className='mapImg'
        />
      </Grid>
      <Grid item md={6} padding={1}>
        <Card>
          <Typography padding={1} variant='h4' align='center'>
            {t("faq.area301")}
          </Typography>
        </Card>
        {generateAccordionPanels("30", expanded300, setExpanded300)}
      </Grid>
      <Grid
        item
        md={6}
        padding={1}
        sx={{ display: { xs: "none", md: "block", lg: "block" } }}>
        <img
          src={require("../images/garden.jpg")}
          alt='garden'
          className='mapImg'
        />
      </Grid>
    </Grid>
  );
};
