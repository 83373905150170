import { Button, Typography } from "@mui/material";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import { useTranslation } from "react-i18next";

export const WhatSpot = () => {
  const { t } = useTranslation();

  const renderTextAndButton = (text: string, buttonLabel: string) => {
    return (
      <div className='boxBubbleTop'>
        <Typography>{text}</Typography>
        {buttonLabel && (
          <Button variant='contained' color='primary'>
            {buttonLabel}
          </Button>
        )}
      </div>
    );
  };

  const breakpoints = {
    0: {
      slidesPerView: 1,
    },
    600: {
      slidesPerView: 2,
    },
    1280: {
      slidesPerView: 3,
    },
  };

  return (
    <Swiper
      spaceBetween={10}
      breakpoints={breakpoints}
      navigation
      rewind={true}
      pagination={{ clickable: true }}
      autoplay={{
        delay: 6000,
        disableOnInteraction: true,
      }}
      modules={[Autoplay, Navigation]}>
      <SwiperSlide>
        <div style={{ position: "relative" }}>
          <img
            src={
              window.innerWidth < 800
                ? require("../../images/picnic-sm.jpg")
                : require("../../images/picnic.jpg")
            }
            alt='picnic'
            style={{ width: "100%", height: "auto" }}
          />
          {renderTextAndButton(t("what.picnic"), "")}
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div style={{ position: "relative" }}>
          <img
            src={
              window.innerWidth < 800
                ? require("../../images/garden-sm.jpg")
                : require("../../images/garden.jpg")
            }
            alt='garden'
            style={{ width: "100%", height: "auto" }}
          />
          {renderTextAndButton(t("what.garden"), "")}
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div style={{ position: "relative" }}>
          <img
            src={
              window.innerWidth < 800
                ? require("../../images/birds-sm.jpg")
                : require("../../images/birds.jpg")
            }
            alt='birds'
            style={{ width: "100%", height: "auto" }}
          />
          {renderTextAndButton(t("what.birds"), "")}
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div style={{ position: "relative" }}>
          <img
            src={
              window.innerWidth < 800
                ? require("../../images/pond-sm.jpg")
                : require("../../images/pond.jpg")
            }
            alt='pond'
            style={{ width: "100%", height: "auto" }}
          />
          {renderTextAndButton(t("what.nature"), "")}
        </div>
      </SwiperSlide>
    </Swiper>
  );
};
