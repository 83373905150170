// Language loading
import enFlag from "../images/flags/en.png";
import esFlag from "../images/flags/es.png";
import caFlag from "../images/flags/ca.png";
import itFlag from "../images/flags/it.png";
import ruFlag from "../images/flags/ru.png";

export const supportedLanguages = [
  { code: "ca", name: "Catala", flag: caFlag, route: "/home", showSimplified: false, simp:"" },
  { code: "es", name: "Español", flag: esFlag, route: "/home", showSimplified: false, simp:"" },
  { code: "en-gb", name: "English", flag: enFlag, route: "/home", showSimplified: false, simp:"" },
  { code: "it", name: "Italiano", flag: itFlag, route: "/simpleHome", showSimplified: true, simp:"Semplificato" },
  { code: "ru", name: "Русский", flag: ruFlag, route: "/simpleHome", showSimplified: true, simp:"Упрощенная версия" },
  //{ code: "de", name: "Deutsche", flag: deFlag, route: "/simpleHome" },
  // Add more supported languages as needed
];


// Park prices
const adultPrice = 11.5;
const kidsPrice = Math.round(adultPrice * 0.7 * 2) / 2;
const retiredPrice = Math.round(adultPrice * 0.75 * 2) / 2;
const yearPassMultiplication = 3.5;
const yearSeasonalMultiplication = 2;
export const prices = {
  year: 2024,
  booking: 40,
  adult: adultPrice,
  adultYearPass: Math.ceil(adultPrice * yearPassMultiplication),
  adultSeasonalPass: Math.ceil(adultPrice * yearSeasonalMultiplication),
  kids: kidsPrice,
  kidsYearPass: Math.ceil(kidsPrice * yearPassMultiplication),
  kidsSeasonalPass:Math.ceil(kidsPrice * yearSeasonalMultiplication),
  toddler: 0,
  retired: retiredPrice,
  retiredYearPass: Math.ceil(retiredPrice * yearPassMultiplication),
  retiredSeasonalPass:Math.ceil(retiredPrice * yearSeasonalMultiplication ),
  teacher: adultPrice * 0.85,
  disabledA: adultPrice * 0.75,
  disabledK: Math.round((adultPrice * 0.7 * 0.75) * 2) / 2,
  guide: 0.5,
  donkey: 1,
  golf: 2,
  chicken: 0.5,
  tour: 0.5,
  workshop: 5,
};

// Calendar Colors
export const colors = [
  "white",
  "#6ba37e",
  "#ffe438",
  "#ee3434",
  "#2DA8B8",
  "#CC66CC",
  'gray',
];
  
// List of seasons with start and end dates
export const seasons = [
  //2023
  {
    start: "2023-09-04",
    end: "2023-11-27",
    season: "low",
  },
  {
    start: "2023-11-27",
    end: "2023-12-27",
    season: "lowend",
  },
  //2024
  {
    start: "2024-03-08",
    end: "2024-05-31",
    season: "low",
  },
  {
    start: "2024-05-31",
    end: "2024-06-18",
    season: "highstart",
  },
  {
    start: "2024-06-18",
    end: "2024-09-02",
    season: "high",
  },
  {
    start: "2024-09-02",
    end: "2024-11-24",
    season: "low",
  },
  {
    start: "2024-11-24",
    end: "2024-12-27",
    season: "lowend",
  },
  //2025
   {
     start: "2025-02-28",
     end: "2025-12-03",
     season: "comingsoon",
   }
];
  
// List of special days that we are closed/open. Format yyyy-mm-dd
export const special = [
  // 2024
  { date: "2024-06-15", start: false },
  { date: "2024-06-16", start: false },
  { date: "2024-06-18", start: false },
  { date: "2024-06-19", start: false },
  { date: "2024-06-20", start: false },
  { date: "2024-06-21", start: false },
  { date: "2024-07-26", start: false },
  { date: "2024-07-27", start: false },
  { date: "2024-07-28", start: false },
  { date: "2024-10-27", start: false },
  { date: "2024-09-07", start: false },
  { date: "2024-09-08", start: false },
  { date: "2024-05-20", start: "10:30", end: "17:00" },
  { date: "2024-05-01", start: "10:30", end: "17:00" },
  { date: "2024-09-24", start: "10:30", end: "17:00" },
  { date: "2024-09-23", start: "10:30", end: "17:00" },
  { date: "2024-11-01", start: "10:30", end: "17:00" },
  { date: "2024-12-06", start: "11:00", end: "16:00" },
  { date: "2024-12-25", start: "11:00", end: "16:00" },
  { date: "2024-12-26", start: "11:00", end: "16:00" },
  
];

//  List of time tables
export const times: {
  [key: string]: {
    [key: string]:
    | boolean
    | { start: string; end: string; tourAM: string; tourPM?: string }
    | string;
  };
} = {
  low: {
      Mon: false, //closed
      Tue: false, //closed
      Wed: false, //closed
      Thu: false, //closed
      Fri: false, //closed
      Sat: { start: "10:30", end: "17:00", tourAM: "11:00" },
      Sun: { start: "10:30", end: "17:00", tourAM: "11:00" },
      Holiday: { start: "10:30", end: "17:00", tourAM: "11:00"  },
    },
    lowend: {
      Mon: false, //closed
      Tue: false, //closed
      Wed: false, //closed
      Thu: false, //closed
      Fri: false, //closed
      Sat: { start: "11:00", end: "16:00", tourAM: "11:30" },
      Sun: { start: "11:00", end: "16:00", tourAM: "11:30" },
      Holiday: { start: "11:00", end: "16:00", tourAM: "11:30" },
    },
    highstart: {
      Mon: false, //closed
      Tue: false, //closed
      Wed: false, //closed
      Thu: false, //closed
      Fri: false, //closed
      Sat: { start: "10:30", end: "17:00", tourAM: "11:00" },
      Sun: { start: "10:30", end: "20:00", tourAM: "11:00", tourPM: "17:00" },
      Holiday: { start: "10:30", end: "17:00", tourAM: "11:00" },
    },
    high: {
      Mon: false, //closed,
      Tue: { start: "10:30", end: "18:00", tourAM: "11:00" },
      Wed: { start: "10:30", end: "18:00", tourAM: "11:00" },
      Thu: { start: "10:30", end: "18:00", tourAM: "11:00" },
      Fri: { start: "10:30", end: "20:00", tourAM: "11:00", tourPM: "17:00" },
      Sat: { start: "10:30", end: "17:00", tourAM: "11:00" },
      Sun: { start: "10:30", end: "20:00", tourAM: "11:00", tourPM: "17:00" },
      Holiday: { start: "10:30", end: "18:00", tourAM: "11:00" },
    },
};