import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import {
  TextField,
  Button,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
  Link,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  SelectChangeEvent,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const styles = {
  textField: {
    borderRadius: "4px",
    padding: "10px",
    "@media (min-width: 900px)": {
      width: "40%",
    },
    "@media (max-width: 900px)": {
      width: "100%",
    },
  },
  textFieldFull: {
    borderRadius: "4px",
    padding: "10px",
    "@media (min-width: 900px)": {
      width: "80%",
    },
    "@media (max-width: 900px)": {
      width: "100%",
    },
  },
};

const ContactForm = () => {
  const { t, i18n } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [button, setButton] = useState("");
  const [link, setLink] = useState("");

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    areaCode: Yup.string().required("Area code is required"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .min(9, "Phone number must have at least 9 digits"),
    tc: Yup.boolean().oneOf([true]),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      areaCode: "+34",
      phone: "",
      email: "",
      dropdown: "",
      message: "",
      tc: false,
    },

    validationSchema,

    onSubmit: async (values) => {
      try {
        const selectedOption = formik.values.dropdown; // Get the selected value
        const selectedOptionText = t(`contact.${selectedOption}`);

        const response = await axios.post(
          "https://script.google.com/macros/s/AKfycbwOIlWJwGWKZbv6gS4n8Dm3p6mN9UA7OVV1EefJnlA1d2GI1U3zYavnim7zP45STE7h/exec",
          {
            name: values.name,
            email: values.email,
            phone: values.areaCode + values.phone,
            dropdown: selectedOptionText,
            message: values.message,
            tc: values.tc,
            origin: window.location.origin,
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        alert(response.data);

        formik.resetForm();
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDropdownChange = (event: SelectChangeEvent<string>) => {
    formik.handleChange(event);

    if (event.target.name === "dropdown") {
      if (event.target.value === "option1") {
        setTitle(t("menu.faq") || "");
        setMessage(t("contact.setMessageFAQ") || "");
        setButton(`${t("contact.setButtonFAQ")}`);
        setLink("FAQ");
        setOpenDialog(true);
      }
      if (event.target.value === "option2") {
        setTitle(t("menu.party") || "");
        setMessage(t("contact.setMessage") || "");
        setButton(`${t("contact.setButtonParty")}`);
        setLink("party");
        setOpenDialog(true);
      }
      if (event.target.value === "option6") {
        setTitle(t("menu.school") || "");
        setMessage(t("contact.setMessage") || "");
        setButton(`${t("contact.setButtonSchool")}`);
        setLink("school");
        setOpenDialog(true);
      }
    }
  };

  return (
    <Grid container>
      <Grid container padding={2}>
        <Grid item xs={12}>
          <Typography variant='h5'>{t("contact.contact") + ":"}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            WhatsApp{": "}
            <Link
              href='https://wa.me/34693009949'
              target='_blank'
              rel='noopener noreferrer'>
              <img
                src={require("../../images/parkNumber.png")}
                alt='parkNumber'
                height={"20px"}
              />
              <WhatsAppIcon />
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <Grid container padding={2}>
        <Grid item xs={12}>
          <Typography variant='h5'>{t("contact.form") + ":"}</Typography>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              name='name'
              label={t("contact.name")}
              value={formik.values.name}
              onChange={formik.handleChange}
              required
              sx={styles.textField}
            />
            <TextField
              name='phone'
              label={t("contact.phone")}
              type='tel'
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              sx={styles.textField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <TextField
                      name='areaCode'
                      value={formik.values.areaCode}
                      onChange={formik.handleChange}
                      size='small'
                      sx={{ width: "75px" }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              name='email'
              label='email@email.com'
              type='email'
              value={formik.values.email}
              onChange={formik.handleChange}
              fullWidth
              required
              sx={styles.textField}
            />
            <FormControl fullWidth required sx={styles.textField}>
              <InputLabel>{t("contact.reason")}</InputLabel>
              <Select
                name='dropdown'
                value={formik.values.dropdown}
                label='Dropdown'
                onChange={handleDropdownChange}>
                <MenuItem value='option1'>{t("contact.option1")}</MenuItem>
                <MenuItem value='option2'>{t("contact.option2")}</MenuItem>
                <MenuItem value='option3'>{t("contact.option3")}</MenuItem>
                <MenuItem value='option4'>{t("contact.option4")}</MenuItem>
                <MenuItem value='option5'>{t("contact.option5")}</MenuItem>
                <MenuItem value='option6'>{t("contact.option6")}</MenuItem>
              </Select>
            </FormControl>
            <TextField
              name='message'
              label={t("contact.message")}
              multiline
              rows={5}
              value={formik.values.message}
              onChange={formik.handleChange}
              fullWidth
              required
              sx={styles.textFieldFull}
            />
            <FormControl>
              <FormControlLabel
                required
                control={
                  <Checkbox
                    name='tc'
                    checked={formik.values.tc}
                    onChange={formik.handleChange}
                  />
                }
                label={t("contact.tc")}
              />
            </FormControl>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              sx={styles.textFieldFull}>
              {t("contact.submit")}
            </Button>
          </form>
        </Grid>
      </Grid>
      {/* Dialog for displaying the message */}
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        BackdropProps={{
          style: { backdropFilter: "blur(2px)" },
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}>
        <IconButton
          edge='end'
          color='inherit'
          onClick={handleDialogClose}
          aria-label='close'
          sx={{ position: "absolute", top: "2%", right: "3%" }}>
          <HighlightOffIcon />
        </IconButton>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Typography>
            {message || "Default message when no option is selected"}
          </Typography>
          <Link href={`/${i18n.language}/${link}`}>{button}</Link>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default ContactForm;
