import React from "react";
import { Grid, IconButton, Typography, Link } from "@mui/material";
import { Instagram, Facebook, YouTube } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleClickPrivacy = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();

    const language = i18n.language;

    if (window.location.pathname.includes("/simpleHome")) {
      i18n.changeLanguage("en-gb").then(() => {
        // Store the selected language in local storage
        localStorage.setItem("language", "en-gb");
        navigate(`/${language}/TC`);
        window.scrollTo(0, 0);
      });
    } else {
      localStorage.setItem("language", i18n.language);
      navigate(`/${language}/TC`);
      window.scrollTo(0, 0);
    }
  };

  const TikTok = ({ color = "rgba(0, 0, 0, 0.54)" }) => {
    return (
      <svg fill={color} viewBox='0 0 50 50' width='30px'>
        <path d='M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z' />
      </svg>
    );
  };

  return (
    <Grid container>
      <Grid
        item
        lg={4}
        container
        direction='row'
        bgcolor='lightcoral'
        justifyContent='center'>
        <Grid item>
          <Link
            aria-label='follow us on instagram.com'
            href='https://www.instagram.com/eljardidelsocells/'
            target='_blank'
            rel='noopener noreferrer'>
            <IconButton aria-label='instagram.com'>
              <Instagram />
            </IconButton>
          </Link>
          <Link
            aria-label='follow us on facebook.com'
            href='https://www.facebook.com/ElJardiDelsOcells/'
            target='_blank'
            rel='noopener noreferrer'>
            <IconButton aria-label='facebook.com'>
              <Facebook />
            </IconButton>
          </Link>
          <Link
            aria-label='follow us on youtube.com'
            href='https://www.youtube.com/@eljardidelsocells8439/'
            target='_blank'
            rel='noopener noreferrer'>
            <IconButton aria-label='youtube.com'>
              <YouTube />
            </IconButton>
          </Link>
          <Link
            aria-label='follow us on tiktok.com'
            href='https://www.tiktok.com/@eljardidelsocells/'
            target='_blank'
            rel='noopener noreferrer'>
            <IconButton aria-label='tiktok.com'>
              <TikTok />
            </IconButton>
          </Link>
        </Grid>
        <Grid item>
          <IconButton aria-label='@eljardidelsocells'>
            <Typography>@eljardidelsocells</Typography>
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        item
        lg={8}
        container
        direction='row'
        bgcolor='beige'
        justifyContent='space-around'
        alignContent='center'>
        <Grid item>
          <Link
            aria-label={t("footer.privacy").toString()}
            href='#'
            onClick={handleClickPrivacy}>
            <Typography variant='caption'>{t("footer.privacy")}</Typography>
          </Link>
        </Grid>
        <Grid item>
          <Typography variant='caption'>©The Bird Gardens 2008-2023</Typography>
        </Grid>
        <Grid item>
          <Typography variant='caption'>
            @CopyrightDeposit.com # 0030115
          </Typography>
        </Grid>
        <Grid item>
          <Link
            aria-label='Learn more at hostpapa.com'
            href='https://www.hostpapa.com'
            target='_blank'
            rel='noopener noreferrer'>
            <Typography variant='caption'>{t("footer.host")}</Typography>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};
