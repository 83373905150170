import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { NavBarTop } from "../components/Bars/NavBarTop";
import { Prices } from "../components/Prices";
import TimeTable from "../components/TimeTable";
import Map from "../components/Map";
import Weather from "../components/Weather";
import { MapInfo } from "../components/MapInfo";
import { WhatSpot } from "../components/Carousel/WhatSpot";
import { useTranslation } from "react-i18next";
import { Footer } from "../components/Bars/Footer";

export const PlanYourVisit = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = t("menu.planYourVisit");
  });
  return (
    <Grid>
      <Grid container>
        <NavBarTop />
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Grid className='pageImg'>
            <div style={{ position: "relative" }}>
              <img
                src={require("../images/headers/gould.jpg")}
                alt=''
                className='pageImg'
              />
              <div className='pageTitle'>
                <Typography variant='h4' className='boxBorder' padding={1}>
                  {t("menu.planYourVisit")}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Prices party={false} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid item xs={12}>
            <Weather />
          </Grid>
          <Grid item xs={12}>
            <TimeTable tour={true} calendar={false} holiday={false} />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <TimeTable tour={false} calendar={false} holiday={false} />
        </Grid>
        <Grid item xs={12} lg={6} className={"boxBlue"}>
          <MapInfo />
        </Grid>
        <Grid item xs={12} lg={6} className={"boxBlue"}>
          <Map />
        </Grid>
        <Grid item xs={12} lg={12} className={"boxGreen"} padding={1}>
          <TimeTable tour={false} calendar={true} holiday={false} />
        </Grid>
        <Grid item xs={12} lg={12} className={"boxBlue"} padding={1}>
          <WhatSpot />
        </Grid>
        <Grid container>
          <Footer />
        </Grid>
      </Grid>
    </Grid>
  );
};
