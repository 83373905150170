import React, { Component, MouseEvent } from "react";
import ParkMap from "../images/ParkMap.png";
import Popover from "@mui/material/Popover";
import { Grid, Typography } from "@mui/material";
import i18n from "i18next";

import AddLocationIcon from "@mui/icons-material/AddLocation";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";

interface Location {
  name: string;
  information: string;
  image?: string;
  positions: { top: string; left: string }[];
}

interface InteractiveMapProps {}

interface InteractiveMapState {
  selectedLocation: Location | null;
  anchorEl: null | HTMLElement;
  locations: Location[];
  isPopoverOpen: boolean;
  isPhoneInVerticalOrientation: boolean;
}

class InteractiveMap extends Component<
  InteractiveMapProps,
  InteractiveMapState
> {
  constructor(props: InteractiveMapProps) {
    super(props);
    this.state = {
      selectedLocation: null,
      anchorEl: null,
      locations: [
        {
          name: "intMap.picnic.title",
          information: "intMap.picnic.desc",
          image: "picnicAreaTop",
          positions: [{ top: "40.3%", left: "11.6%" }],
        },
        {
          name: "intMap.picnic.title",
          information: "intMap.picnic.desc",
          image: "picnicAreaMiddle",
          positions: [{ top: "32.4%", left: "50.7%" }],
        },
        {
          name: "intMap.picnic.title",
          information: "intMap.picnic.desc",
          image: "picnicArea",
          positions: [{ top: "50.3%", left: "78.7%" }],
        },
        {
          name: "intMap.gym.title",
          information: "intMap.gym.desc",
          positions: [{ top: "29.5%", left: "12.65%" }],
        },
        {
          name: "intMap.play.title",
          information: "intMap.play.desc",
          image: "kidsArea",
          positions: [
            { top: "26.1%", left: "21.3%" },
            { top: "63%", left: "88.7%" },
          ],
        },
        {
          name: "intMap.play.title",
          information: "intMap.play.desc",

          positions: [{ top: "26.1%", left: "21.3%" }],
        },
        {
          name: "intMap.party.title",
          information: "intMap.party.desc",
          image: "picnicAreaParty",
          positions: [{ top: "36.5%", left: "24%" }],
        },
        {
          name: "intMap.donkey.title",
          information: "intMap.donkey.desc",
          image: "donkeys",
          positions: [{ top: "56.8%", left: "9.5%" }],
        },
        {
          name: "intMap.woodland.title",
          information: "intMap.woodland.desc",
          image: "woodlandWalk",
          positions: [{ top: "54.9%", left: "59.65%" }],
        },
        {
          name: "intMap.frog.title",
          information: "intMap.frog.desc",
          image: "pond",
          positions: [{ top: "57.5%", left: "73%" }],
        },
        {
          name: "intMap.bat.title",
          information: "intMap.bat.desc",
          image: "batArea",
          positions: [{ top: "39.5%", left: "59.2%" }],
        },
        {
          name: "intMap.insect.title",
          information: "intMap.insect.desc",
          image: "insectopia",
          positions: [
            { top: "21.5%", left: "32.7%" },
            { top: "20.9%", left: "26.8%" },
          ],
        },
        {
          name: "intMap.bee.title",
          information: "intMap.bee.desc",
          image: "beeArea",
          positions: [{ top: "16.2%", left: "35.2%" }],
        },
        {
          name: "intMap.golf.title",
          information: "intMap.golf.desc",
          image: "golf",
          positions: [{ top: "21.5%", left: "84%" }],
        },
        {
          name: "intMap.entrance.title",
          information: "intMap.entrance.desc",
          positions: [{ top: "12.8%", left: "84.6%" }],
        },
        {
          name: "intMap.minisParking.title",
          information: "intMap.minisParking.desc",
          positions: [{ top: "13.8%", left: "90.8%" }],
        },
        {
          name: "intMap.prioParking.title",
          information: "intMap.prioParking.desc",
          positions: [{ top: "7.9%", left: "91.6%" }],
        },
        {
          name: "intMap.gift.title",
          information: "intMap.gift.desc",
          positions: [{ top: "16.8%", left: "83.8%" }],
        },
        {
          name: "intMap.wc.title",
          information: "intMap.wc.desc",
          image: "wc",
          positions: [{ top: "18.9%", left: "71.9%" }],
        },
        {
          name: "intMap.redList.title",
          information: "intMap.redList.desc",
          image: "redList",
          positions: [{ top: "6.5%", left: "59.5%" }],
        },
        {
          name: "intMap.tour.title",
          information: "intMap.tour.desc",
          positions: [{ top: "9.5%", left: "79.5%" }],
        },
        {
          name: "intMap.soon.title",
          information: "intMap.soon.desc",
          positions: [{ top: "57.5%", left: "36.1%" }],
        },
        {
          name: "intMap.hide.title",
          information: "intMap.hide.desc",
          image: "hide",
          positions: [{ top: "66.5%", left: "14.5%" }],
        },
        // Add more locations here
      ],
      isPopoverOpen: false,
      // Check if the device is a phone and in vertical orientation
      isPhoneInVerticalOrientation: false,
    };
  }

  componentDidMount() {
    // Add event listener for window resize (orientation change)
    window.addEventListener("resize", this.handleWindowResize);
    // Check the initial orientation immediately when the component mounts
    this.checkDeviceOrientation();
  }

  componentWillUnmount() {
    // Remove the event listener when the component unmounts
    window.removeEventListener("resize", this.handleWindowResize);
  }

  // Function to handle window resize (orientation change)
  handleWindowResize = () => {
    // Check the new orientation and update state
    this.checkDeviceOrientation();
  };

  // Function to check device orientation
  checkDeviceOrientation = () => {
    const isPhoneInVerticalOrientation =
      window.matchMedia("(orientation: portrait)").matches ||
      (window.innerWidth <= 768 && window.innerHeight > window.innerWidth);
    this.setState({ isPhoneInVerticalOrientation });
  };

  handleLocationClick = (location: Location, event: MouseEvent) => {
    this.setState({
      selectedLocation: location,
      anchorEl: event.currentTarget as HTMLElement,
    });
  };

  handleClosePopover = () => {
    this.setState({ anchorEl: null });
    this.setState({ isPopoverOpen: false });
  };

  render() {
    const {
      selectedLocation,
      anchorEl,
      locations,
      isPhoneInVerticalOrientation,
    } = this.state;
    const open = Boolean(anchorEl);
    const t = i18n.t.bind(i18n);

    return (
      <Grid container justifyContent={"center"}>
        {isPhoneInVerticalOrientation && (
          <Grid item>
            <Typography color={"error"}>{t("intMap.rotate")}</Typography>
            <ScreenRotationIcon />
          </Grid>
        )}
        <Grid item>
          <div style={{ position: "relative", width: "100%", height: "auto" }}>
            <img
              src={ParkMap}
              alt='Zoo Map'
              style={{
                width: "100%",
                height: "auto",
                position: "relative",
              }}
            />

            {locations.map((location, index) =>
              location.positions.map((position, posIndex) => (
                <LocationMarker
                  key={`${index}-${posIndex}`}
                  location={location}
                  position={position}
                  onClick={(event: MouseEvent) =>
                    this.handleLocationClick(location, event)
                  }
                />
              ))
            )}

            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={this.handleClosePopover}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}>
              <Grid padding={1}>
                {selectedLocation && (
                  // Left Box
                  <Grid container>
                    {selectedLocation.image ? (
                      <Grid
                        item
                        //xs={12}
                        xs={selectedLocation.image ? 5 : 12}
                        textAlign={"center"}>
                        <img
                          style={{ width: "25vw", height: "auto" }}
                          src={require(`../images/map/${selectedLocation.image}.jpg`)}
                          alt={selectedLocation.name}
                        />
                      </Grid>
                    ) : null}
                    {/* Right Box */}
                    <Grid
                      container
                      xs={selectedLocation.image ? 7 : 12}
                      //xs={12}
                      padding={2}
                      direction='column'
                      textAlign='center'
                      alignSelf={"center"}
                      alignContent={"center"}>
                      <Grid item>
                        <Typography variant='h6'>
                          {t(selectedLocation.name)}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          {t(selectedLocation.information)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  cursor: "pointer",
                }}
                onClick={this.handleClosePopover}>
                <HighlightOffIcon onClick={this.handleClosePopover} />
              </Grid>
            </Popover>
          </div>
        </Grid>
      </Grid>
    );
  }
}

interface LocationMarkerProps {
  location: Location;
  position: { top: string; left: string };
  onClick: (event: MouseEvent) => void;
}

const LocationMarker: React.FC<LocationMarkerProps> = ({
  location,
  position,
  onClick,
}) => (
  <AddLocationIcon
    style={{
      position: "absolute",
      width: "4%",
      height: "4%",
      top: position.top,
      left: position.left,
      color: "red",
      zIndex: 2,
      cursor: "pointer",
    }}
    onClick={onClick}
  />
);

export default InteractiveMap;
