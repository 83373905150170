import React, { useEffect, useState } from "react";
import {
  Card,
  Grid,
  CardHeader,
  Typography,
  Table,
  TableContainer,
  TableCell,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export const Weather = () => {
  const { t } = useTranslation();

  const className = "boxBlue";

  const [weatherData, setWeatherData] = useState<null | {
    current: {
      temperature: number;
      weather: any;
      icon: any;
    };
    forecast: {
      date: string;
      temperature: number;
      weather: any;
      icon: any;
    }[];
  }>(null);

  useEffect(() => {
    // Fetch weather data from OpenWeatherMap API
    const fetchWeatherData = async () => {
      const apiKey = "55c2775193f6d818a37b1e740e987c9d";
      const city = "Vilafranca%20del%20Penedès,ES";
      const apiUrl = `https://api.openweathermap.org/data/2.5/forecast?q=${city}&appid=${apiKey}`;

      try {
        const response = await fetch(apiUrl);
        const data = await response.json();

        // Extract necessary data from the API response
        const weatherData = extractWeatherData(data);

        setWeatherData(weatherData);
      } catch (error) {
        console.error("Error fetching weather data:", error);
      }
    };

    const extractWeatherData = (data: any) => {
      const weatherData = {
        current: {
          temperature: data.list[0].main.temp - 273.15,
          weather: t(`weather.${data.list[0].weather[0].main}`),
          icon: data.list[0].weather[0].icon,
        },
        forecast: [] as {
          date: string;
          temperature: number;
          weather: any;
          icon: any;
        }[],
      };

      const middayHour = 12; // Hour for midday, increment of 3's
      const langCode = t("langCode");

      for (let i = 0; i < data.list.length; i++) {
        const forecastItem = data.list[i];
        const forecastDateTime = new Date(forecastItem.dt * 1000);

        // Check if the forecast time is close to midday
        if (forecastDateTime.getUTCHours() === middayHour) {
          const forecastData = {
            date: forecastDateTime.toLocaleDateString(langCode, {
              weekday: "long",
            }),
            temperature: forecastItem.main.temp - 273.15,
            weather: t(`weather.${forecastItem.weather[0].main}`),
            icon: forecastItem.weather[0].icon,
          };

          weatherData.forecast.push(forecastData);
        }
      }

      return weatherData;
    };

    fetchWeatherData();
  }, [t]);

  if (!weatherData) {
    return <div>Loading...</div>;
  }

  return (
    <Grid padding={1}>
      <Grid className={className}>
        <Card className='boxBorder'>
          <CardHeader
            title={<Typography variant='h5'>{t("weather.title")}</Typography>}
            className={className}
          />
          <TableContainer className={className}>
            <Table padding='none'>
              <tbody>
                <TableRow>
                  <TableCell>{t("time.today")}</TableCell>
                  <TableCell>
                    {weatherData.current.temperature.toFixed(1)}°C
                  </TableCell>
                  <TableCell>{weatherData.current.weather}</TableCell>
                  <TableCell>
                    <img
                      src={`https://openweathermap.org/img/w/${weatherData.current.icon}.png`}
                      alt='Current weather icon'
                    />
                  </TableCell>
                </TableRow>
                {weatherData.forecast.map((item) => (
                  <TableRow key={item.date}>
                    <TableCell>{item.date}</TableCell>
                    <TableCell>{item.temperature.toFixed(1)}°C</TableCell>
                    <TableCell>{item.weather}</TableCell>
                    <TableCell>
                      <img
                        src={`https://openweathermap.org/img/w/${item.icon}.png`}
                        alt='Weather icon'
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Weather;
