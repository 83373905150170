import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/es";
import axios from "axios";
import { useTranslation } from "react-i18next";

// Calculate 12 months from current date
const currentDate = new Date();
const maxAllowedDate = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth() + 12,
  currentDate.getDate()
);

const options = [
  "schoolForm.specialOpt.special",
  "schoolForm.specialOpt.wheel",
  "schoolForm.specialOpt.both",
  "schoolForm.specialOpt.no",
];

function SampleForm() {
  const { t, i18n } = useTranslation();
  const languageCode = i18n.language;
  const formattedLanguage = new Intl.DisplayNames([languageCode], {
    type: "language",
  }).of(languageCode);

  const validationSchema = Yup.object({
    name: Yup.string().required(t("yup.nameReq") || ""),
    schoolName: Yup.string().required(t("yup.nameReq") || ""),
    telephone: Yup.string()
      .required(t("yup.phoneReq") || "")
      .matches(/^\+\d{2}\d{9}$/, t("yup.phoneInv") || ""),
    email: Yup.string()
      .required(t("yup.emailReq") || "")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        t("yup.emailInvalid") || ""
      ),
    cif: Yup.string().required(t("yup.CIF") || ""),
    address: Yup.string().required(t("yup.address") || ""),
    postCode: Yup.string()
      .required(t("yup.addressCodeReq") || "")
      .matches(/^\d{5}$/, t("yup.addressCodeMin") || ""),
    teacherCounter: Yup.number()
      .required(t("yup.countTeachReq") || "")
      .min(2, t("yup.countTeachMin") || "")
      .test({
        name: "teacherToStudentRatio",
        test: function (value) {
          const studentsCounter = this.parent.studentsCounter;
          const studentsMinAge = this.parent.studentsMinAge;

          // Define the ratios based on age range
          let requiredRatio;
          if (studentsMinAge >= 4 && studentsMinAge <= 8) {
            requiredRatio = 1 / 7; // Ratio for ages 4-8
          } else if (studentsMinAge >= 9 && studentsMinAge <= 15) {
            requiredRatio = 1 / 9; // Ratio for ages 9-15
          } else {
            // Default ratio if age doesn't match the specified ranges
            requiredRatio = 1 / 10; // Default ratio for other ages
          }

          // Check if teacherCounter meets the required ratio
          if (value * (1 / requiredRatio) < studentsCounter) {
            return this.createError({
              path: "teacherCounter",
              message: t("yup.countTeachRatio") || "",
            });
          }
          return true;
        },
      }),

    studentsCounter: Yup.number()
      .required(t("yup.countStudentsReq") || "")
      .min(5, t("yup.countAgeMin") || "")
      .max(54, t("yup.countAgeMax") || ""),
    datepicker: Yup.date()
      .required(t("yup.dateReq") || "")
      .min(new Date(), t("yup.dateMin") || "")
      .max(maxAllowedDate, t("yup.dateMax") || ""),
    timeArrive: Yup.string()
      .required(t("yup.timeReq") || "")
      .matches(
        /^(10:[3-5][0-9]|11:[0-5][0-9]|12:00)$/,
        t("yup.startTimeMinMax") || ""
      ),
    timeLeave: Yup.string()
      .required(t("yup.timeReq") || "")
      .matches(
        /^(12:00|12:[0-5][0-9]|1[3-6]:[0-5][0-9]|17:00)$/,
        t("yup.endTimeMinMax") || ""
      ),
    specialDropdown: Yup.string().required(t("yup.schoolType") || ""),
    specialCount: Yup.string().test({
      name: "specialCount",
      test: function (value) {
        const selectedOption = this.parent.specialDropdown;
        const studentsCounter = this.parent.studentsCounter;
        if (options.indexOf(selectedOption) !== 3) {
          if (
            !value ||
            parseInt(value) < 1 ||
            parseInt(value) > studentsCounter
          ) {
            return this.createError({
              path: "specialCount",
              message: t("yup.countStudentsSpecial") || "",
            });
          }
        }
        return true;
      },
    }),
    studentsMinAge: Yup.number()
      .required(t("yup.studentMinReq") || "")
      .min(4, t("yup.studentMin") || "")
      .max(18, t("yup.studentMax") || ""),
    studentsMaxAge: Yup.number()
      .required(t("yup.studentMinReq") || "")
      .min(4, t("yup.studentMin") || "")
      .max(18, t("yup.studentMax") || ""),
    tC: Yup.boolean()
      .oneOf([true], t("yup.tcAgree") || "")
      .required(t("yup.tcReq") || ""),
  });

  const [showAdditionalCount, setShowAdditionalCount] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      schoolName: "",
      cif: "",
      telephone: "+34",
      email: "",
      address: "",
      postCode: "",
      teacherCounter: "",
      studentsCounter: "",
      datepicker: "",
      timeArrive: "10:30",
      timeLeave: "15:00",
      specialDropdown: "",
      specialCount: "",
      studentsMinAge: "",
      studentsMaxAge: "",
      message: "",
      language: formattedLanguage,
      tC: false,
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      try {
        const selectedOption = formik.values.specialDropdown; // Get the selected value
        const selectedOptionText = t(`${selectedOption}`);
        const formattedDate = dayjs(values.datepicker).format("YYYY-MM-DD");

        const response = await axios.post(
          "https://script.google.com/macros/s/AKfycbzJZ7i8owJYYc0xoQR0R_hSiYMjoMwe6RLWpd3qdvN1HSa0jY2hrZcdyekiPzUxOfFAfQ/exec",
          {
            name: values.name,
            schoolName: values.schoolName,
            cif: values.cif,
            telephone: values.telephone,
            email: values.email,
            address: values.address,
            postCode: values.postCode,
            teacherCounter: values.teacherCounter,
            studentsCounter: values.studentsCounter,
            datepicker: formattedDate,
            timeArrive: values.timeArrive,
            timeLeave: values.timeLeave,
            specialDropdown: selectedOptionText,
            specialCount: values.specialCount,
            studentsMinAge: values.studentsMinAge,
            studentsMaxAge: values.studentsMaxAge,
            message: values.message,
            tC: values.tC,
            language: values.language,
            origin: window.location.origin,
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        alert(response.data);

        formik.resetForm();
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const handleDropdownChange = (
    event: React.ChangeEvent<{ value: string }>
  ) => {
    const selectedOption = event.target.value;
    setShowAdditionalCount(options.indexOf(selectedOption) < 3);
    formik.handleChange(event);
  };

  return (
    <Grid>
      <Typography
        variant='h4'
        padding={1.5}
        sx={{ backgroundColor: "#4caf50" }}>
        {t("schoolForm.title")}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} justifyContent={"center"}>
          {/* spacer */}
          <Grid item xs={12}>
            <Divider variant='middle' orientation='vertical' />
          </Grid>

          {/* name */}
          <Grid item md={3}>
            <TextField
              label={t("schoolForm.name")}
              id='name'
              name='name'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              fullWidth
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>

          {/* school name */}
          <Grid item md={3}>
            <TextField
              label={t("schoolForm.nameSchool")}
              id='schoolName'
              name='schoolName'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.schoolName}
              fullWidth
              error={
                formik.touched.schoolName && Boolean(formik.errors.schoolName)
              }
              helperText={formik.touched.schoolName && formik.errors.schoolName}
            />
          </Grid>

          {/* --- spacer --- */}
          <Grid item xs={12}>
            <Divider variant='middle' orientation='horizontal' />
            <Typography variant='h6' padding={1}>
              {t("schoolForm.invoiceSpace")}
            </Typography>
          </Grid>

          {/* cif/nif */}
          <Grid item md={3}>
            <TextField
              label='CIF/NIF'
              id='cif'
              name='cif'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.cif}
              fullWidth
              error={formik.touched.cif && Boolean(formik.errors.cif)}
              helperText={formik.touched.cif && formik.errors.cif}
            />
          </Grid>

          {/* address */}
          <Grid item md={5}>
            <TextField
              label={t("schoolForm.address")}
              id='address'
              name='address'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
              fullWidth
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
            />
          </Grid>

          {/* post code */}
          <Grid item md={2}>
            <TextField
              label={t("schoolForm.postCode")}
              id='postCode'
              name='postCode'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.postCode}
              fullWidth
              error={formik.touched.postCode && Boolean(formik.errors.postCode)}
              helperText={formik.touched.postCode && formik.errors.postCode}
            />
          </Grid>

          {/* telephone */}
          <Grid item md={4}>
            <TextField
              type={"tel"}
              label={t("schoolForm.tel")}
              id='telephone'
              name='telephone'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.telephone}
              fullWidth
              error={
                formik.touched.telephone && Boolean(formik.errors.telephone)
              }
              helperText={formik.touched.telephone && formik.errors.telephone}
            />
          </Grid>

          {/* email */}
          <Grid item md={6}>
            <TextField
              type={"email"}
              label='Email'
              id='email'
              name='email'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              fullWidth
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>

          {/* --- spacer --- */}
          <Grid item xs={12}>
            <Divider variant='middle' orientation='horizontal' />
            <Typography variant='h6' padding={1}>
              {t("schoolForm.att")}
            </Typography>
          </Grid>

          {/* teachers/monitors */}
          <Grid item md={3}>
            <TextField
              type='number'
              label={t("schoolForm.teacher")}
              id='teacherCounter'
              name='teacherCounter'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.teacherCounter}
              fullWidth
              error={
                formik.touched.teacherCounter &&
                Boolean(formik.errors.teacherCounter)
              }
              helperText={
                formik.touched.teacherCounter && formik.errors.teacherCounter
              }
            />
          </Grid>

          {/* students */}
          <Grid item md={3}>
            <TextField
              type='number'
              label={t("schoolForm.students")}
              id='studentsCounter'
              name='studentsCounter'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.studentsCounter}
              fullWidth
              error={
                formik.touched.studentsCounter &&
                Boolean(formik.errors.studentsCounter)
              }
              helperText={
                formik.touched.studentsCounter && formik.errors.studentsCounter
              }
            />
          </Grid>

          {/* special student dropdown */}
          <Grid item xs={9} md={3}>
            <TextField
              select
              label={t("schoolForm.special")}
              id='specialDropdown'
              name='specialDropdown'
              onChange={handleDropdownChange}
              onBlur={formik.handleBlur}
              value={formik.values.specialDropdown}
              fullWidth
              error={
                formik.touched.specialDropdown &&
                Boolean(formik.errors.specialDropdown)
              }
              helperText={
                formik.touched.specialDropdown && formik.errors.specialDropdown
              }>
              {options.map((option) => (
                <MenuItem key={option} value={option}>
                  {t(option)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* special count input (conditional) */}
          {showAdditionalCount && (
            <Grid item xs={9} md={2}>
              <TextField
                type='number'
                label={t("schoolForm.specialCount")}
                id='specialCount'
                name='specialCount'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.specialCount}
                fullWidth
                error={
                  formik.touched.specialCount &&
                  Boolean(formik.errors.specialCount)
                }
                helperText={
                  formik.touched.specialCount && formik.errors.specialCount
                }
              />
            </Grid>
          )}

          {/* --- spacer --- */}
          <Grid item md={12}></Grid>

          {/* students min age*/}
          <Grid item md={3}>
            <TextField
              type='number'
              label={t("schoolForm.minAge")}
              id='studentsMinAge'
              name='studentsMinAge'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.studentsMinAge}
              fullWidth
              error={
                formik.touched.studentsMinAge &&
                Boolean(formik.errors.studentsMinAge)
              }
              helperText={
                formik.touched.studentsMinAge && formik.errors.studentsMinAge
              }
            />
          </Grid>

          {/* students max age*/}
          <Grid item md={3}>
            <TextField
              type='number'
              label={t("schoolForm.maxAge")}
              id='studentsMaxAge'
              name='studentsMaxAge'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.studentsMaxAge}
              fullWidth
              error={
                formik.touched.studentsMaxAge &&
                Boolean(formik.errors.studentsMaxAge)
              }
              helperText={
                formik.touched.studentsMaxAge && formik.errors.studentsMaxAge
              }
            />
          </Grid>

          {/* --- spacer --- */}
          <Grid item xs={12}>
            <Divider variant='middle' orientation='horizontal' />
            <Typography variant='h6' padding={1}>
              {t("schoolForm.dateSpace")}
            </Typography>
          </Grid>

          {/* date */}
          <Grid item xs={9} md={4}>
            <TextField
              type='date'
              label={t("schoolForm.date")}
              id='datepicker'
              name='datepicker'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.datepicker}
              fullWidth
              InputLabelProps={{ shrink: true }}
              error={
                formik.touched.datepicker && Boolean(formik.errors.datepicker)
              }
              helperText={formik.touched.datepicker && formik.errors.datepicker}
            />
          </Grid>

          {/* time arrive */}
          <Grid item xs={9} md={3}>
            <TextField
              type='time'
              label={t("schoolForm.timeArrive")}
              id='timeArrive'
              name='timeArrive'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.timeArrive}
              fullWidth
              InputLabelProps={{ shrink: true }}
              error={
                formik.touched.timeArrive && Boolean(formik.errors.timeArrive)
              }
              helperText={formik.touched.timeArrive && formik.errors.timeArrive}
            />
          </Grid>

          {/* time leave */}
          <Grid item xs={9} md={3}>
            <TextField
              type='time'
              label={t("schoolForm.timeADepart")}
              id='timeLeave'
              name='timeLeave'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.timeLeave}
              fullWidth
              InputLabelProps={{ shrink: true }}
              error={
                formik.touched.timeLeave && Boolean(formik.errors.timeLeave)
              }
              helperText={formik.touched.timeLeave && formik.errors.timeLeave}
            />
          </Grid>

          {/* message */}
          <Grid item md={10}>
            <TextField
              label={t("schoolForm.message") + " . . ."}
              id='message'
              name='message'
              multiline
              rows={4}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.message}
              fullWidth
            />
          </Grid>

          {/* --- spacer --- */}
          <Grid item xs={12}>
            <Divider variant='middle' orientation='horizontal' />
          </Grid>

          {/* terms and conditions */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id='tC'
                  name='tC'
                  checked={formik.values.tC}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  color='primary'
                />
              }
              label={t("schoolForm.tc")}
            />
            <FormHelperText
              error={formik.touched.tC && Boolean(formik.errors.tC)}
              style={{ textAlign: "center" }}>
              {formik.touched.tC && formik.errors.tC}
            </FormHelperText>
          </Grid>

          {/* submit */}
          <Grid item xs={12}>
            <Button type='submit' variant='contained' color='primary'>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

export default SampleForm;
