import React from "react";
import {
  Grid,
  Typography,
  Divider,
  ListItemText,
  ListItem,
  Avatar,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";

const infoCount = 3; // Specify the number of rules here
const ruleCount = 9; // Specify the number of rules here

export const PartyInfo = () => {
  const { t } = useTranslation();

  const renderInfoListItem = (infoCount: number) => {
    const icons = [
      <FlagCircleIcon color='success' />,
      <TableRestaurantIcon color='success' />,
      <LocalActivityIcon color='success' />,
    ];

    return Array.from(Array(infoCount), (_, index) => index + 1).map(
      (infoIndex) => {
        const title = t(`party.infoListTitle${infoIndex}`);
        const sub = t(`party.infoListSub${infoIndex}`);
        const icon = icons[infoIndex - 1] || <FlagCircleIcon color='success' />;

        return (
          <React.Fragment key={infoIndex}>
            <ListItem dense={true}>
              <ListItemText
                primary={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar>{icon}</Avatar>
                    <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
                      {title}
                    </span>
                  </div>
                }
                primaryTypographyProps={{ fontWeight: "bold" }}
                secondary={sub}
              />
            </ListItem>
            <Divider variant='middle' />
          </React.Fragment>
        );
      }
    );
  };

  const renderRuleListItem = (ruleCount: number) => {
    return Array.from(Array(ruleCount), (_, index) => index + 1).map(
      (ruleIndex) => {
        const title = t(`party.ruleTitle${ruleIndex}`);
        const sub = t(`party.ruleSub${ruleIndex}`);

        return (
          <React.Fragment key={ruleIndex}>
            <ListItem dense={true} key={ruleIndex}>
              <ListItemText
                primary={`${ruleIndex}. ${title}`}
                primaryTypographyProps={{ fontWeight: "bold" }}
                secondary={sub}
              />
            </ListItem>
            <Divider variant='middle' />
          </React.Fragment>
        );
      }
    );
  };

  return (
    <Grid>
      <Grid padding={1}>
        <Grid className='boxBlue'>
          <Grid className='boxBorder'>
            <Typography variant='h4' padding={1}>
              {t("party.title")}
            </Typography>
            <Grid item padding={1}>
              <Typography variant='h5'>{t("party.info")}</Typography>
              <Grid>
                <Typography variant='body2'>{t("party.info1")}</Typography>
                {renderInfoListItem(infoCount)}
                <Typography variant='body2'>{t("party.info2")}</Typography>
                <Typography variant='body2'>{t("party.info3")}</Typography>
                <Typography variant='body2'>{t("party.info4")}</Typography>
              </Grid>
            </Grid>
            <Divider variant='middle' />
            <Grid item padding={1}>
              <Typography variant='h5'>{t("party.rules")}</Typography>
              <Grid item textAlign={"left"}>
                {renderRuleListItem(ruleCount)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
