import React from "react";
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DesktopDatePicker,
  DesktopTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import "dayjs/locale/es";

export const PartyForm = () => {
  const { t, i18n } = useTranslation();
  const languageCode = i18n.language;
  const formattedLanguage = new Intl.DisplayNames([languageCode], {
    type: "language",
  }).of(languageCode);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("yup.nameReq") || ""),
    areaCode: Yup.string().required(t("yup.areaCode") || ""),
    phone: Yup.string()
      .matches(/^[0-9]+$/, t("yup.phoneCount") || "")
      .min(9, t("yup.phoneNum") || "")
      .required(t("yup.phoneReq") || ""),
    email: Yup.string()
      .email(t("yup.invalidEmail") || "")
      .required(t("yup.emailReq") || ""),
    radio: Yup.string().required(t("yup.partyType") || ""),
    adult: Yup.number()
      .min(1, t("yup.adultMin") || "")
      .required(t("yup.adultReq") || ""),
    date: Yup.date().required(t("yup.dateReq") || ""),
    startTime: Yup.string().required(t("yup.startTimeReq") || ""),
    endTime: Yup.string().required(t("yup.endTimeReq") || ""),
    message: Yup.string().required(t("yup.messageReq") || ""),
    radioPayment: Yup.string().required(t("yup.paymentType") || ""),
    tour: Yup.boolean().oneOf([true], t("yup.tourReq") || ""),
    tc: Yup.boolean().oneOf([true], t("yup.tcReq") || ""),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      areaCode: "+34",
      phone: "",
      email: "",
      radio: "",
      adult: 0,
      kids: 0,
      toddler: 0,
      date: null,
      startTime: null,
      endTime: null,
      tour: false,
      message: "",
      radioPayment: "",
      language: formattedLanguage,
      tc: false,
    },

    validationSchema,

    onSubmit: async (values) => {
      try {
        const selectedOption = formik.values.radio; // Get the selected value
        const selectedOptionText = t(`party.${selectedOption}`);
        const selectedPaymentOption = formik.values.radioPayment; // Get the selected value
        const selectedPaymentOptionText = t(
          `party.payment.${selectedPaymentOption}`
        );
        const formattedDate = dayjs(values.date).format("YYYY-MM-DD");
        const formattedStartTime = dayjs(values.startTime).format("HH:mm");
        const formattedEndTime = dayjs(values.endTime).format("HH:mm");

        const response = await axios.post(
          "https://script.google.com/macros/s/AKfycbyF31VHxTJreNLpCsQQjGN3TzjVWBCyL7Pxhg854iyxV0wzOwEKEMCxySkeHhLcLKyi/exec",
          {
            name: values.name,
            email: values.email,
            phone: values.areaCode + values.phone,
            radio: selectedOptionText,
            adult: values.adult,
            kids: values.kids,
            toddler: values.toddler,
            date: formattedDate,
            startTime: formattedStartTime,
            endTime: formattedEndTime,
            tour: values.tour,
            message: values.message,
            radioPayment: selectedPaymentOptionText,
            tc: values.tc,
            language: values.language,
            origin: window.location.origin,
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        alert(response.data);

        formik.resetForm();
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  return (
    <Grid padding={2} sx={{ justifyContent: "center" }}>
      <Grid item xs={12} className='boxGreen'>
        <Grid item padding={2} width={"98%"}>
          <Typography margin={2} variant='h5'>
            {t("party.form")}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Grid container margin={1} spacing={1}>
              <Grid item xs={12} lg={3}>
                <TextField
                  name='name'
                  label={t("party.name")}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <TextField
                  name='phone'
                  label={t("party.phone")}
                  type='tel'
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <TextField
                          name='areaCode'
                          value={formik.values.areaCode}
                          onChange={formik.handleChange}
                          size='small'
                          sx={{ width: "75px" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <TextField
                  name='email'
                  label='email@email.com'
                  type='email'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Divider variant='middle' />

            <Grid container margin={1} spacing={1}>
              <FormControl
                fullWidth
                error={formik.touched.radio && Boolean(formik.errors.radio)}>
                <FormLabel>{t("party.type")}</FormLabel>
                <RadioGroup
                  row
                  name='radio'
                  value={formik.values.radio}
                  onChange={formik.handleChange}>
                  <FormControlLabel
                    value='option1'
                    label={t("party.option1")}
                    control={<Radio size='small' />}
                  />
                  <FormControlLabel
                    value='option2'
                    label={t("party.option2")}
                    control={<Radio size='small' />}
                  />
                  <FormControlLabel
                    value='option3'
                    label={t("party.option3")}
                    control={<Radio size='small' />}
                  />
                </RadioGroup>
                {formik.touched.radio && formik.errors.radio && (
                  <FormHelperText error>{formik.errors.radio}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid container margin={1} spacing={1}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='tour'
                      checked={formik.values.tour}
                      onChange={formik.handleChange}
                    />
                  }
                  label={t("party.tour")}
                />
                {formik.touched.tc && formik.errors.tc && (
                  <FormHelperText error>{formik.errors.tc}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid container margin={1} spacing={1}>
              <Grid item xs={12} lg={4}>
                <TextField
                  label={t("prices.adult")}
                  type='number'
                  variant='filled'
                  name='adult'
                  value={formik.values.adult}
                  onChange={formik.handleChange}
                  error={formik.touched.adult && Boolean(formik.errors.adult)}
                  helperText={formik.touched.adult && formik.errors.adult}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  label={t("prices.kids")}
                  type='number'
                  variant='filled'
                  name='kids'
                  value={formik.values.kids}
                  onChange={formik.handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  label={t("prices.toddler")}
                  type='number'
                  variant='filled'
                  name='toddler'
                  value={formik.values.toddler}
                  onChange={formik.handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container margin={1} spacing={1}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale='es'>
                <Grid item xs={12} lg={4}>
                  <DesktopDatePicker
                    label={t("party.date")}
                    disablePast
                    value={formik.values.date}
                    onChange={(date) => {
                      formik.setFieldValue("date", date);
                    }}
                    sx={{ width: "100%" }}
                  />
                  {formik.touched.date && Boolean(formik.errors.date) && (
                    <FormHelperText error>{formik.errors.date}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} lg={4}>
                  <DesktopTimePicker
                    minTime={dayjs().set("hour", 10).set("minute", 30)}
                    maxTime={dayjs().set("hour", 20).startOf("hour")}
                    label={t("party.start")}
                    value={formik.values.startTime}
                    onChange={(time) => {
                      formik.setFieldValue("startTime", time);
                    }}
                    sx={{ width: "100%" }}
                  />
                  {formik.touched.startTime &&
                    Boolean(formik.errors.startTime) && (
                      <FormHelperText error>
                        {formik.errors.startTime}
                      </FormHelperText>
                    )}
                </Grid>
                <Grid item xs={12} lg={4}>
                  <DesktopTimePicker
                    minTime={dayjs().set("hour", 9).startOf("hour")}
                    maxTime={dayjs().set("hour", 20).startOf("hour")}
                    label={t("party.end")}
                    value={formik.values.endTime}
                    onChange={(time) => {
                      formik.setFieldValue("endTime", time);
                    }}
                    sx={{ width: "100%" }}
                  />
                  {formik.touched.endTime && Boolean(formik.errors.endTime) && (
                    <FormHelperText error>
                      {formik.errors.endTime}
                    </FormHelperText>
                  )}
                </Grid>
              </LocalizationProvider>
            </Grid>

            <Divider variant='middle' />

            <Grid margin={1} spacing={1}>
              <FormControl
                fullWidth
                error={
                  formik.touched.radioPayment &&
                  Boolean(formik.errors.radioPayment)
                }>
                <FormLabel>{t("party.payment.title")}</FormLabel>
                <RadioGroup
                  row
                  name='radioPayment'
                  value={formik.values.radioPayment}
                  onChange={formik.handleChange}>
                  <FormControlLabel
                    value='option1'
                    label={t("party.payment.option1")}
                    control={<Radio size='small' />}
                  />
                  <FormControlLabel
                    value='option2'
                    label={t("party.payment.option2")}
                    control={<Radio size='small' />}
                  />
                  <FormControlLabel
                    value='option3'
                    label={t("party.payment.option3")}
                    control={<Radio size='small' />}
                  />
                </RadioGroup>
                {formik.touched.radioPayment && formik.errors.radioPayment && (
                  <FormHelperText error>
                    {formik.errors.radioPayment}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Divider variant='middle' />

            <Grid container margin={1} spacing={1}>
              <TextField
                name='message'
                label={t("party.message")}
                multiline
                rows={5}
                value={formik.values.message}
                onChange={formik.handleChange}
                fullWidth
              />
            </Grid>

            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    name='tc'
                    checked={formik.values.tc}
                    onChange={formik.handleChange}
                  />
                }
                label={t("contact.tc") + t("party.tc")}
              />
              {formik.touched.tc && formik.errors.tc && (
                <FormHelperText error>{formik.errors.tc}</FormHelperText>
              )}
            </FormControl>

            <Button fullWidth variant='contained' color='primary' type='submit'>
              {t("contact.submit")}
            </Button>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};
