import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { NavBarTop } from "../components/Bars/NavBarTop";
import { Carousel } from "../components/Carousel/Carousel";
import { Information } from "../components/Information";
import Map from "../components/Map";
import { WhatSpot } from "../components/Carousel/WhatSpot";
import { Footer } from "../components/Bars/Footer";
import { useTranslation } from "react-i18next";

const MainPage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = t("parkName");
  });
  return (
    <Grid>
      <Grid container>
        <NavBarTop />
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Carousel />
        </Grid>
        <Grid item md={12} lg={6} className='boxGreen'>
          <Information />
        </Grid>
        <Grid item md={12} lg={6} className='boxGreen'>
          <Map />
        </Grid>
        <Grid
          item
          xs={12}
          className='boxBlue'
          sx={{ display: { xs: "none", md: "block", lg: "block" } }}>
          <WhatSpot />
        </Grid>
        <Grid container>
          <Footer />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MainPage;
