import { Grid, Link, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const Map = () => {
  const { t } = useTranslation();
  return (
    <Grid className='mapImg' id='map'>
      <div style={{ position: "relative" }}>
        <img src={require("../images/map.jpg")} alt='map' className='mapImg' />
        <div className='boxBubbleMap' style={{ flexDirection: "column" }}>
          <Link
            href='https://goo.gl/maps/we1LHiTKh9vaWmgg6'
            target='_blank'
            rel='noopener noreferrer'>
            <Typography>{t("map.view")}</Typography>
          </Link>
        </div>
      </div>
    </Grid>
  );
};

export default Map;
