import {
  Grid,
  Typography,
  CircularProgress,
  Button,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const StyledIframe = styled("iframe")({
  width: "100%",
  border: "none",
  height: "2300px",
  "@media (min-width:900px)": {
    width: "80%",
    height: "2100px",
  },
});

const LoadingContainer = styled("div")({
  position: "relative",
  width: "100%",
  height: "2300px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const LoadingOverlay = styled(CircularProgress)({
  position: "absolute",
  top: "50vh",
});

export const GiftCardFrame = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [copied, setCopied] = useState(false);
  const [isWithinDateRange, setIsWithinDateRange] = useState(false);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText("black2023").then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000); // Hide the notification after 2 seconds
    });
  };

  useEffect(() => {
    const currentDate = new Date();
    const startDate = new Date("2023-11-17");
    const endDate = new Date("2023-11-27");
    setIsWithinDateRange(currentDate >= startDate && currentDate <= endDate);
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>{t("gift.info1")}</Typography>
        <Typography>{t("gift.info2")}</Typography>
      </Grid>

      {isWithinDateRange && (
        <Grid item xs={12} bgcolor='black' padding={1}>
          <Typography color='red'>
            {t("carousel.blackFridaySub")}
            <Tooltip title={copied ? "Copied!" : ""} placement='top'>
              <Button
                endIcon={<ContentCopyIcon />}
                color='warning'
                onClick={handleCopy}>
                BLACK2023
              </Button>
            </Tooltip>
          </Typography>
          <Typography color='red' variant='body2'>
            {t("carousel.blackFridaySub2")}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <LoadingContainer>
          {isLoading && <LoadingOverlay />} {/* Show loading icon at the top */}
          <StyledIframe
            src='https://squareup.com/gift/ML381BARER02M/order'
            title='Square GiftCard'
            onLoad={handleIframeLoad}
          />
        </LoadingContainer>
      </Grid>
    </Grid>
  );
};
