import React, { useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Typography, Grid, Button, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AnimalIcon, BirdIcon, FrogIcon, LeafIcon } from "../../data/svgIcons";
import images from "../../data/images";
import { Link } from "react-router-dom";
import BugReportIcon from "@mui/icons-material/BugReport";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import InfoIcon from "@mui/icons-material/Info";
import { AddToCalendarButton } from "add-to-calendar-button-react";

interface WorkshopDate {
  date: string;
  paymentUrl: string;
}

interface Workshop {
  title: string;
  dates: WorkshopDate[];
  description: string;
  icon: JSX.Element;
  imageUrl: string;
}

export const WorkshopTimetable = () => {
  const { t } = useTranslation();

  // State to manage the visibility of past events
  const [showPastEvents, setShowPastEvents] = useState(false);

  // Workshop content
  const workshops: Workshop[] = [
    {
      title: t("workshop.timetable.birdDay.title"),
      dates: [
        {
          date: "07-04-2024",
          paymentUrl: "",
        },
        {
          date: "04-08-2024",
          paymentUrl:
            "https://taller-eljardidelsocells.square.site/product/taller-p-jaros-aut-ctonos/88",
        },
      ],
      description: t("workshop.timetable.birdDay.description"),
      icon: <BirdIcon />,
      imageUrl: images.gould,
    },
    {
      title: t("workshop.timetable.animalDay.title"),
      dates: [
        {
          date: "05-05-2024",
          paymentUrl: "",
        },
        {
          date: "01-09-2024",
          paymentUrl:
            "https://taller-eljardidelsocells.square.site/product/taller-animales/89",
        },
      ],
      description: t("workshop.timetable.animalDay.description"),
      icon: <AnimalIcon />,
      imageUrl: images.animalDay,
    },
    {
      title: t("workshop.timetable.insectDay.title"),
      dates: [
        {
          date: "02-06-2024",
          paymentUrl: "",
        },
        {
          date: "01-12-2024",
          paymentUrl:
            "https://taller-eljardidelsocells.square.site/product/taller-insectos/90",
        },
      ],
      description: t("workshop.timetable.insectDay.description"),
      icon: <BugReportIcon />,
      imageUrl: images.insectDay,
    },
    {
      title: t("workshop.timetable.frogDay.title"),
      dates: [
        { date: "07-07-2024", paymentUrl: "" },
        {
          date: "03-11-2024",
          paymentUrl:
            "https://taller-eljardidelsocells.square.site/product/taller-ranas/91",
        },
      ],
      description: t("workshop.timetable.frogDay.description"),
      icon: <FrogIcon />,
      imageUrl: images.pond,
    },
    {
      title: t("workshop.timetable.organicDay.title"),
      dates: [
        {
          date: "06-10-2024",
          paymentUrl:
            "https://taller-eljardidelsocells.square.site/product/taller-cultivo-org-nico/95",
        },
      ],
      description: t("workshop.timetable.organicDay.description"),
      icon: <LeafIcon />,
      imageUrl: images.organicDay,
    },
    // Add more workshop data as needed
  ];

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // Set time to midnight

  const formatDateForCalendar = (dateString: string) => {
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };

  // Flatten and sort the events by date
  const sortedEvents = workshops
    .flatMap((workshop, index) =>
      workshop.dates.map((dateInfo) => ({
        ...workshop,
        ...dateInfo,
        key: `${index}-${dateInfo.date}`,
      }))
    )
    .sort((a, b) => {
      const [dayA, monthA, yearA] = a.date.split("-");
      const [dayB, monthB, yearB] = b.date.split("-");
      const dateA = new Date(`${yearA}-${monthA}-${dayA}`).getTime();
      const dateB = new Date(`${yearB}-${monthB}-${dayB}`).getTime();
      return dateA - dateB;
    });

  const renderTimelineElements = () => {
    return sortedEvents.map((event) => {
      const [day, month, year] = event.date.split("-");
      const eventDate = new Date(`${year}-${month}-${day}`);
      const isPastEvent = eventDate < currentDate;

      if (isPastEvent && !showPastEvents) {
        return null;
      }

      if (isPastEvent) {
        return (
          <VerticalTimelineElement
            key={`${event.key}-past`}
            contentStyle={{ padding: 0, paddingTop: 2 }}
            iconStyle={{ background: "#4caf50", color: "#fff" }}
            icon={event.icon}>
            <Typography variant='body1'>
              {t("workshop.timetable.past")}:{" "}
              <b>
                {event.title}
                <Tooltip arrow title={event.description}>
                  <InfoIcon />
                </Tooltip>
              </b>{" "}
              {event.date}
            </Typography>
          </VerticalTimelineElement>
        );
      } else {
        return (
          <VerticalTimelineElement
            key={`${event.key}-future`}
            iconStyle={{ background: "#4caf50", color: "#fff" }}
            icon={event.icon}
            date={event.date}>
            <Grid container alignItems='center'>
              <Grid item md={8}>
                <Typography variant='h4'>{event.title}</Typography>
                <Grid container justifyContent='center' paddingTop={1}>
                  {event.paymentUrl ? (
                    <>
                      <Grid item md={6}>
                        <Typography>{event.date}</Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <AddToCalendarButton
                          name={event.title}
                          description={event.description}
                          startDate={formatDateForCalendar(event.date)}
                          location='El Jardi Dels Ocells'
                          startTime='11:30'
                          endTime='13:00'
                          options={["Google", "Apple"]}
                          timeZone='Europe/Madrid'
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid item>
                      <Typography>{event.date}</Typography>
                    </Grid>
                  )}
                </Grid>

                <Typography>{event.description}</Typography>
                {event.paymentUrl ? (
                  <Link
                    to={event.paymentUrl}
                    target='_blank'
                    rel='noopener noreferrer'>
                    <Button sx={{ margin: 1, padding: 1 }} variant='contained'>
                      {event.title} {t("workshop.timetable.payment")}
                    </Button>
                  </Link>
                ) : (
                  <Typography variant='body1' color={"red"}>
                    <b>{t("workshop.timetable.paymentSoon")}</b>
                  </Typography>
                )}
              </Grid>
              <Grid item md={4} paddingTop={2}>
                <img src={event.imageUrl} alt={event.title} width='100%' />
              </Grid>
            </Grid>
          </VerticalTimelineElement>
        );
      }
    });
  };

  return (
    <Grid container>
      <Grid container justifyContent='center'>
        <Button
          variant='contained'
          onClick={() => setShowPastEvents(!showPastEvents)}>
          {showPastEvents
            ? t("workshop.timetable.showLess")
            : t("workshop.timetable.showMore")}
        </Button>
      </Grid>
      <Grid container>
        <VerticalTimeline layout='1-column-left'>
          {renderTimelineElements()}
          <VerticalTimelineElement
            iconStyle={{ background: "#4caf50", color: "#fff" }}
            icon={<NewReleasesIcon />}
            date={t("workshop.timetable.more") || ""}
          />
        </VerticalTimeline>
      </Grid>
    </Grid>
  );
};
