const images: { [key: string]: string } = {
    birdAreaSm: require("../images/birdArea-sm.jpg"),
    birdArea: require("../images/birdArea.jpg"),
    birdsSm: require("../images/birds-sm.jpg"),
    birds: require("../images/birds.jpg"),
    birdSignSm: require("../images/birdSign-sm.jpg"),
    birdSign: require("../images/birdSign.jpg"),
    budgieSm: require("../images/budgie-sm.jpg"),
    budgie: require("../images/budgie.jpg"),
    forestSm: require("../images/forest-sm.jpg"),
    forest: require("../images/forest.jpg"),
    gardenSm: require("../images/garden-sm.jpg"),
    garden: require("../images/garden.jpg"),
    giftCardsSm: require("../images/Gift_Cards-sm.jpg"),
    giftCards: require("../images/Gift_Cards.jpg"),
    golfSm: require("../images/golf-sm.jpg"),
    golf: require("../images/golf.jpg"),
    gouldSm: require("../images/gould-sm.jpg"),
    gould: require("../images/gould.jpg"),
    mapParkSm: require("../images/mapPark-sm.jpg"),
    mapPark: require("../images/mapPark.jpg"),
    parkMapCopy: require("../images/ParkMap - Copy.png"),
    parkMapSm: require("../images/ParkMap-sm.png"),
    parkMap: require("../images/ParkMap.png"),
    parkNumber: require("../images/parkNumber.png"),
    paseAnualSm: require("../images/pase_anual-sm.jpg"),
    paseAnual: require("../images/pase_anual.jpg"),
    picnicSm: require("../images/picnic-sm.jpg"),
    picnic: require("../images/picnic.jpg"),
    pondSm: require("../images/pond-sm.jpg"),
    pond: require("../images/pond.jpg"),
    purpleStarlingSm: require("../images/purple_starling-sm.jpg"),
    purpleStarling: require("../images/purple_starling.jpg"),
    shopSm: require("../images/shop-sm.jpg"),
    shop: require("../images/shop.jpg"),
    signSm: require("../images/sign-sm.jpg"),
    sign: require("../images/sign.jpg"),
    animalDay: require("../images/workshop/animalDay.jpg"),
    insectDay: require("../images/workshop/insectDay.jpg"),
    organicDay: require("../images/workshop/organicDay.jpg"),
};

export default images;

export type Images = typeof images;