import React, { useState, useContext } from "react";
import {
  Card,
  Grid,
  CardHeader,
  Typography,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  Button,
  Stack,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import Calendar from "react-calendar";
import "../Calendar.css";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import { seasons, special, times, colors } from "../data/data";

import "dayjs/locale/es";
import "dayjs/locale/en-gb";
import "dayjs/locale/ca";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const daySeconds = 86400;

const timerProps = {
  isPlaying: true,
  size: 120,
  strokeWidth: 6,
};

const renderTime = (dimension: string, time: number) => {
  return (
    <div className='time-wrapper'>
      <div className='time'>{time}</div>
      <div>{dimension}</div>
    </div>
  );
};

const getTimeDays = (time: number) => (time / daySeconds) | 0;

export const TimeTable = ({
  tour,
  calendar,
  holiday,
}: {
  tour: boolean;
  calendar: boolean;
  holiday: boolean;
}) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  //time change tester
  const demo = false;
  let currentDate: Date;

  if (demo) {
    currentDate = new Date("2024-03-16T23:58:00.898Z");
    currentDate.setTime(
      currentDate.getTime() + currentDate.getTimezoneOffset() * 60 * 1000
    ); // Adjust to local time
  } else {
    currentDate = new Date();
    currentDate.setTime(
      currentDate.getTime() + currentDate.getTimezoneOffset() * 60 * 1000
    ); // Adjust to local time
  }

  const [showSpecialDates, setShowSpecialDates] = useState(true);

  const LanguageContext = React.createContext("");
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  let totalMonthsToShow;
  if (window.innerWidth <= 768) {
    totalMonthsToShow = 2; // Months to show
  } else {
    totalMonthsToShow = 8; // Months to show
  }
  const maxShow = 12; // Max months allowed to show
  const [numMonthsToShow, setNumMonthsToShow] = useState(totalMonthsToShow);
  const monthsToShow = [...Array(numMonthsToShow)].map((_, index) => {
    const month = currentMonth + index;
    const year = currentYear + Math.floor(month / 12); // Adjust the year
    return { year, month: month % 12 };
  });
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const className = "boxYellow";
  const className2 = "boxGreen";

  const getFormattedDate = (date: Date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    return `${day}/${month}`;
  };

  const currentSeason = seasons.find(
    (s) => currentDate >= new Date(s.start) && currentDate <= new Date(s.end)
  );

  const getNextSeasonStartDate = (currentDate: Date) => {
    const nextSeason = seasons.find((season) => {
      const seasonStartDate = new Date(season.start);
      return currentDate < seasonStartDate;
    });

    if (nextSeason) {
      return {
        seasonName: nextSeason.season,
        seasonStartDate: new Date(nextSeason.start),
      };
    } else {
      return null; // No upcoming season found
    }
  };

  const nextSeasonInfo = getNextSeasonStartDate(currentDate);

  let targetDate: number;
  let currentTime: number;
  let endTime: number;

  if (nextSeasonInfo) {
    targetDate = nextSeasonInfo.seasonStartDate.getTime(); // Get timestamp in milliseconds
    // Add 2 days to the target date to fix time start error of arrays
    const errorFix = 2 * (24 * 60 * 60 * 1000);
    targetDate += errorFix;
    currentTime = currentDate.getTime(); // Get current timestamp in milliseconds
    endTime = Math.floor((targetDate - currentTime) / 1000); // Convert milliseconds to seconds
  } else {
    targetDate = 0; // Default value if nextSeasonInfo is null
    currentTime = 0; // Default value if nextSeasonInfo is null
    endTime = 0; // Default value if nextSeasonInfo is null
  }

  const DaysBefore = new Date(nextSeasonInfo!.seasonStartDate!);
  DaysBefore.setDate(DaysBefore.getDate() - 7);

  const formatDate = (date: Date | undefined): string => {
    if (!date) return ""; // Handle null or undefined cases

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const seasonText =
    currentSeason &&
    (currentSeason.season === "high" || currentSeason.season === "highstart"
      ? t("time.highSeason")
      : currentSeason.season === "low" || currentSeason.season === "lowend"
      ? t("time.lowSeason")
      : "");

  const generateRowsTours = () => {
    if (currentSeason) {
      const seasonTourTimes = times[currentSeason.season];
      const rows: {
        days: string[];
        tourTimeAM: string;
        tourTimePM: string;
      }[] = Object.entries(seasonTourTimes).reduce(
        (
          acc: {
            days: string[];
            tourTimeAM: string;
            tourTimePM: string;
          }[],
          [dayOfWeek, tourTimes]
        ) => {
          const prevRow = acc[acc.length - 1];

          const formattedDayOfWeek = t(
            `time.weekday.${dayOfWeek.toLowerCase()}`
          );
          let tourTimeAM: string;
          let tourTimePM: string;

          if (typeof tourTimes === "object" && tourTimes.tourAM) {
            tourTimeAM = tourTimes.tourAM + "AM";
          } else if (prevRow && prevRow.tourTimeAM === t("time.closed")) {
            tourTimeAM = t("time.closed");
          } else {
            tourTimeAM = "";
          }

          if (typeof tourTimes === "object" && tourTimes.tourPM) {
            tourTimePM = tourTimes.tourPM + "PM";
          } else if (prevRow && prevRow.tourTimePM === t("time.closed")) {
            tourTimePM = t("time.closed");
          } else {
            tourTimePM = "";
          }

          if (dayOfWeek === "Holiday") {
            // If it's a holiday, add a new row with the holiday info
            acc.push({
              days: [formattedDayOfWeek],
              tourTimeAM,
              tourTimePM,
            });
          } else if (
            prevRow &&
            prevRow.tourTimeAM === tourTimeAM &&
            prevRow.tourTimePM === tourTimePM
          ) {
            if (prevRow.days.length === 1) {
              prevRow.days.push(formattedDayOfWeek);
            } else {
              prevRow.days[1] = formattedDayOfWeek;
            }
          } else {
            acc.push({
              days: [formattedDayOfWeek],
              tourTimeAM,
              tourTimePM,
            });
          }

          return acc;
        },
        []
      );

      const tableRows = rows.map((row, index) => (
        <TableRow key={index}>
          <TableCell>{row.days.join("-")}</TableCell>
          <TableCell>{row.tourTimeAM || t("time.closed")}</TableCell>
          <TableCell>{row.tourTimePM || ""}</TableCell>
        </TableRow>
      ));

      return tableRows;
    }

    return t("time.unavailable");
  };

  const generateRows = (numDays: number, selectedDate: Date | null = null) => {
    if (numDays === 1 && selectedDate) {
      selectedDate = new Date(selectedDate); // Create a copy of the date
      selectedDate.setHours(selectedDate.getHours() + 2); // Increase time by 2 hours
    }
    const rows = [];
    const currentDates = selectedDate ? new Date(selectedDate) : currentDate;
    //console.log(currentDates);
    for (let i = 0; i < numDays; i++) {
      const nextDate = new Date(
        currentDates.getTime() + i * 24 * 60 * 60 * 1000
      );
      //console.log(nextDate);
      const formattedDate = getFormattedDate(nextDate);
      const dayOfWeek = nextDate.toLocaleDateString("en-gb", {
        weekday: "short",
      });

      const season = seasons.find(
        (s) => nextDate >= new Date(s.start) && nextDate < new Date(s.end)
      );
      const timetable = season ? times[season.season][dayOfWeek] : null;

      // Check if the current day is a special day
      //nextDate.setDate(nextDate.getDate() + 1);

      const specialDay = special.find(
        (s) => s.date === nextDate.toISOString().slice(0, 10)
      );
      console.log(nextDate);
      console.log(specialDay);
      let cellContent = t("time.closed");
      if (specialDay) {
        if (specialDay.start === false) {
          cellContent = t("time.closed");
        } else {
          cellContent = `${specialDay.start} - ${specialDay.end}`;
        }
      } else if (timetable) {
        if (typeof timetable === "object") {
          cellContent = `${timetable.start} - ${timetable.end}`;
        }
      }

      const dayLabel =
        i === 0 && !selectedDate
          ? t("time.today")
          : t(`time.weekday.${dayOfWeek.toLowerCase()}`);

      rows.push(
        <TableRow key={i}>
          <TableCell>
            {dayLabel} ({formattedDate})
          </TableCell>
          <TableCell>{cellContent}</TableCell>
        </TableRow>
      );
    }

    return rows;
  };

  // Custom function to render content for each day
  const tileContent = ({ date, view }: { date: Date; view: string }) => {
    const day = date.getDay();
    //Get current day
    const currentDate = new Date(
      date
        .toLocaleDateString(lang, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .split("/")
        .reverse()
        .join("/")
    );
    // Check if the current day is a special day
    // Subtract one day from currentDate
    const previousDay = new Date(currentDate);
    previousDay.setDate(previousDay.getDate() + 1);
    const specialDay = special.find(
      (s) => s.date === previousDay.toISOString().slice(0, 10)
    );

    // Get season of current day
    let currentSeason = seasons.find((s) => {
      const startDate = new Date(s.start);
      const endDate = new Date(s.end);
      const isCurrentDateInRange =
        currentDate >= startDate && currentDate < endDate;
      return isCurrentDateInRange;
    });

    // Add your conditions to determine the color of specific days
    const fontSize = "1vh";

    if (specialDay) {
      if (specialDay.start === false || specialDay.start === "false") {
        return (
          <div style={{ background: colors[0], fontSize: fontSize }}>
            {t("time.closed")}
          </div>
        );
      } else {
        if (currentSeason && currentSeason.season === "low") {
          return (
            <div style={{ background: colors[1], fontSize: fontSize }}>
              {t("time.open")}
            </div>
          );
        } else if (currentSeason && currentSeason.season === "highstart") {
          return (
            <div style={{ background: colors[2], fontSize: fontSize }}>
              {t("time.open")}
            </div>
          );
        } else if (currentSeason && currentSeason.season === "high") {
          return (
            <div style={{ background: colors[2], fontSize: fontSize }}>
              {t("time.open")}
            </div>
          );
        } else if (currentSeason && currentSeason.season === "lowend") {
          return (
            <div style={{ background: colors[5], fontSize: fontSize }}>
              {t("time.open")}
            </div>
          );
        }
      }
    } else if (currentSeason && currentSeason.season === "comingsoon") {
      return (
        <div style={{ background: colors[6], fontSize: fontSize }}>
          {t("time.soon")}
        </div>
      );
    } else if (currentSeason && currentSeason.season === "low") {
      if (day >= 1 && day <= 5) {
        return (
          <div style={{ background: colors[0], fontSize: fontSize }}>
            {t("time.closed")}
          </div>
        );
      } else if (day === 0 || day === 6) {
        return (
          <div style={{ background: colors[1], fontSize: fontSize }}>
            {t("time.open")}
          </div>
        );
      }
    } else if (currentSeason && currentSeason.season === "highstart") {
      if (day >= 1 && day <= 5) {
        return (
          <div style={{ background: colors[0], fontSize: fontSize }}>
            {t("time.closed")}
          </div>
        );
      } else if (day === 0) {
        return (
          <div style={{ background: colors[3], fontSize: fontSize }}>
            {t("time.open")}
          </div>
        );
      } else if (day === 6) {
        return (
          <div style={{ background: colors[4], fontSize: fontSize }}>
            {t("time.open")}
          </div>
        );
      }
    } else if (currentSeason && currentSeason.season === "high") {
      if (day === 1) {
        return (
          <div style={{ background: colors[0], fontSize: fontSize }}>
            {t("time.closed")}
          </div>
        );
      } else if (day >= 2 && day <= 4) {
        return (
          <div style={{ background: colors[2], fontSize: fontSize }}>
            {t("time.open")}
          </div>
        );
      } else if (day === 0 || day === 5) {
        return (
          <div style={{ background: colors[3], fontSize: fontSize }}>
            {t("time.open")}
          </div>
        );
      } else if (day === 6) {
        return (
          <div style={{ background: colors[4], fontSize: fontSize }}>
            {t("time.open")}
          </div>
        );
      }
    } else if (currentSeason && currentSeason.season === "lowend") {
      if (day >= 1 && day <= 5) {
        return (
          <div style={{ background: colors[0], fontSize: fontSize }}>
            {t("time.closed")}
          </div>
        );
      } else if (day === 0 || day === 6) {
        return (
          <div style={{ background: colors[5], fontSize: fontSize }}>
            {t("time.open")}
          </div>
        );
      }
    }
    return (
      <div style={{ background: colors[0], fontSize: fontSize }}>
        {t("time.closed")}
      </div>
    );
  };

  const TableComponent = () => {
    const numDaysDefault = 9;
    const [numDays, setNumDays] = useState(numDaysDefault);
    const [originalNumDays] = useState(numDaysDefault);

    const [showRevertButton, setShowRevertButton] = useState(false);

    const handleAddDays = () => {
      if (numDays < 20) {
        setNumDays(numDays + 3);
        setShowRevertButton(true);

        // Scroll to the bottom of the element after a small delay
        setTimeout(() => {
          const element = document.getElementById("buttonStack");
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 100);
      }
    };

    const handleRevertDays = () => {
      setNumDays(originalNumDays);
      setShowRevertButton(false);
    };

    const [showCalendar, setShowCalendar] = useState(false);
    const [showWeek, setShowWeek] = useState(false);
    const [showMoreDaysButton, setShowMoreDaysButton] = useState(true);
    const [selectedDate, setSelectedDate] = useState<Date | null>(currentDate);

    const handleToggleCalendar = () => {
      setShowCalendar(!showCalendar);
      setShowWeek(!showWeek);
      setShowMoreDaysButton(!showMoreDaysButton);
      setSelectedDate(currentDate);
    };

    const handleDateChange = (value: Date | null) => {
      setSelectedDate(value);
    };

    const LocalizedCalendar = () => {
      const languageCode = useContext(LanguageContext);

      return (
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={languageCode}>
          <DateCalendar
            views={["day"]}
            disablePast
            onChange={handleDateChange}
            dayOfWeekFormatter={(day) => `${day}`}
          />
        </LocalizationProvider>
      );
    };

    if (holiday) {
      // Define the X number of days you want to show
      const numberOfDays = 14;

      // Calculate the date for X days from today
      const endDate = new Date(currentDate);
      endDate.setDate(endDate.getDate() + numberOfDays);

      // Filter special dates that are within the next X days
      const today = new Date(currentDate);
      const nextSpecialDates = special.filter((s) => {
        const specialDate = new Date(s.date);
        return specialDate >= today && specialDate <= endDate;
      });

      const formattedDatesAndTimes = nextSpecialDates.map((date) => {
        const specialDate = new Date(date.date);
        const formattedDate = specialDate.toLocaleDateString(lang, {
          day: "2-digit",
          month: "short",
          year: "numeric",
        });

        const specialDay = special.find(
          (s) => s.date === specialDate.toISOString().slice(0, 10)
        );

        let openingTime = t("time.closed");

        if (specialDay) {
          if (specialDay.start !== false) {
            openingTime = `${specialDay.start}-${specialDay.end}`;
          }
        }

        return `${formattedDate} (${openingTime})`;
      });

      const showSpecialDatesFromLocalStorage =
        localStorage.getItem("showSpecialDates");

      const currentDateStr = currentDate.toDateString();

      if (
        showSpecialDatesFromLocalStorage !== currentDateStr &&
        showSpecialDates &&
        formattedDatesAndTimes.length > 0
      ) {
        return (
          <Grid
            paddingRight={3}
            bgcolor={"pink"}
            width={"100%"}
            textAlign={"center"}
            sx={{ position: "relative" }}>
            <Grid
              lg={12}
              sx={{ display: { xs: "none", md: "none", lg: "block" } }}>
              <Link
                reloadDocument //makes anchor work
                to={"/" + i18n.language + "/planYourVisit#calendar"}
                style={{ color: "inherit" }}>
                <Typography variant='body2'>
                  {t("time.nextSpecialDates")}:{" "}
                  {formattedDatesAndTimes.map((formattedDateAndTime, index) => (
                    <span key={index}>
                      {formattedDateAndTime}
                      {index !== formattedDatesAndTimes.length - 1
                        ? " || "
                        : ""}
                    </span>
                  ))}
                </Typography>
              </Link>
            </Grid>
            <Grid
              lg={12}
              sx={{ display: { xs: "block", md: "block", lg: "none" } }}>
              <Link
                reloadDocument //makes anchor work
                to={"/" + i18n.language + "/planYourVisit#calendar"}
                style={{ color: "inherit" }}>
                <Typography variant='body2'>
                  {t("time.nextSpecialDates")}:{<br />}
                  {formattedDatesAndTimes.map((formattedDateAndTime, index) => (
                    <span key={index}>
                      {formattedDateAndTime}
                      {index !== formattedDatesAndTimes.length - 1 ? (
                        <br />
                      ) : (
                        ""
                      )}
                    </span>
                  ))}
                </Typography>
              </Link>
            </Grid>
            <HighlightOffIcon
              sx={{
                position: "absolute",
                top: "13px",
                right: "5px",
                transform: "translateY(-50%)",
                cursor: "pointer",
                fontSize: "20px",
              }}
              onClick={() => {
                setShowSpecialDates(false);
                localStorage.setItem(
                  "showSpecialDates",
                  currentDate.toDateString()
                ); // Update local storage
              }}
            />
          </Grid>
        );
      } else {
        return null;
      }
    }

    if (tour)
      return (
        <Grid padding={1}>
          <Grid className={className2}>
            <Card className='boxBorder'>
              <CardHeader
                title={
                  <Typography variant='h5'>
                    {t("time.titleTour")} {"/ "}
                    {seasonText}
                  </Typography>
                }
                className={className2}
              />
              <Stack id='tourTime'>
                <TableContainer className={className2}>
                  <Table size='small'>
                    <tbody>{generateRowsTours()}</tbody>
                  </Table>
                </TableContainer>
              </Stack>
              <Typography bgcolor={"lightgoldenrodyellow"}>
                {t("time.tourLang")}
              </Typography>
            </Card>
          </Grid>
        </Grid>
      );
    if (calendar)
      return (
        <Grid container>
          <Grid container spacing={2} padding={2}>
            <Grid item lg={12}>
              <Typography variant='h5' textAlign={"center"}>
                {t("calendar.calendar")}
              </Typography>
              <Grid
                container
                direction='row'
                gap={1}
                justifyContent={"space-around"}>
                {/* CLOSED days */}
                <Typography padding={1} bgcolor={colors[0]}>
                  {t("calendar.closed")}
                </Typography>
                {/* Low Season days */}
                <Typography padding={1} bgcolor={colors[1]}>
                  {t("calendar.low")} 10:30 - 17:00
                </Typography>
                {/* High Season days */}
                <Typography padding={1} bgcolor={colors[2]}>
                  {t("calendar.high")} 10:30 - 18:00
                </Typography>
                {/* High Season days */}
                <Typography padding={1} bgcolor={colors[4]}>
                  {t("calendar.high")} 10:30 - 17:00
                </Typography>
                {/* High Season days */}
                <Typography padding={1} bgcolor={colors[3]}>
                  {t("calendar.high")} 10:30 - 20:00
                </Typography>
                {/* December days */}
                <Typography padding={1} bgcolor={colors[5]}>
                  {t("calendar.Dec")} 11:00 - 16:00
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            {monthsToShow.map(({ year, month }, index) => {
              const calendarDate = new Date(year, month, 1);
              const monthName = calendarDate.toLocaleString(lang, {
                month: "long",
              });
              return (
                <Grid item padding={2} xs={12} sm={6} md={3} key={index}>
                  <Typography variant='h5' gutterBottom>
                    {monthName} {year}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      pointerEvents: "none",
                    }}>
                    <Calendar
                      defaultActiveStartDate={calendarDate}
                      view='month'
                      locale={lang}
                      showNeighboringMonth={false}
                      tileContent={tileContent}
                      showNavigation={false}
                    />
                  </div>
                </Grid>
              );
            })}
          </Grid>
          {isButtonVisible && (
            <Grid item xs={12}>
              <Button
                variant='contained'
                onClick={() => {
                  let newNumMonthsToShow;
                  if (window.innerWidth <= 768) {
                    newNumMonthsToShow = numMonthsToShow + 2;
                  } else {
                    newNumMonthsToShow = numMonthsToShow + 4;
                  }

                  if (newNumMonthsToShow <= maxShow) {
                    setNumMonthsToShow(newNumMonthsToShow);
                  }
                  if (newNumMonthsToShow === maxShow) {
                    // If newNumMonthsToShow is at maxShow, hide the button
                    setIsButtonVisible(false);
                  }
                }}>
                {t("calendar.show")}
              </Button>
            </Grid>
          )}
        </Grid>
      );
    else if (!currentSeason && currentDate.getTime() <= DaysBefore.getTime()) {
      return (
        <Grid margin={1} className='boxYellow'>
          <Grid
            padding={2}
            className='boxBorder'
            container
            justifyContent={"center"}>
            <Grid>
              <Typography variant='h5'>{t("time.title")}</Typography>
              <Typography>{t("time.currentlyClosed")}</Typography>
              <Typography>{t("time.backIn")}</Typography>
            </Grid>
            <Grid
              container
              padding={1}
              direction={"row"}
              justifyContent={"space-around"}>
              <CountdownCircleTimer
                {...timerProps}
                colors='#7E2E84'
                duration={endTime}
                initialRemainingTime={endTime}>
                {({ elapsedTime, color }) => (
                  <span style={{ color }}>
                    {renderTime(
                      t("time.days"),
                      getTimeDays(endTime - elapsedTime)
                    )}
                  </span>
                )}
              </CountdownCircleTimer>
            </Grid>
            <Grid>
              <Typography>
                {formatDate(nextSeasonInfo?.seasonStartDate ?? undefined)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid padding={1} id='calendar'>
          <Grid className={className}>
            <Card className='boxBorder'>
              <CardHeader
                title={<Typography variant='h5'>{t("time.title")}</Typography>}
                subheader={
                  <Typography color={"red"} variant='body2'>
                    {t("time.noReserve")}
                  </Typography>
                }
                className={className}
              />
              {!showCalendar ? (
                <Stack id='timeTable'>
                  <TableContainer className={className}>
                    <Table>
                      <tbody>{generateRows(numDays)}</tbody>
                    </Table>
                  </TableContainer>
                </Stack>
              ) : (
                <Stack id='calendar' className={className}>
                  <LanguageContext.Provider
                    value={localStorage.getItem("language") || "es"}>
                    <LocalizedCalendar />
                  </LanguageContext.Provider>
                  {selectedDate &&
                  selectedDate <= new Date(seasons[seasons.length - 1].end) ? (
                    <TableContainer className={className}>
                      <Table>
                        <tbody>{generateRows(1, selectedDate)}</tbody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Typography variant='subtitle1'>
                      {t("time.unavailable")}
                    </Typography>
                  )}
                </Stack>
              )}
              <Stack
                id='buttonStack'
                spacing={2}
                direction='row'
                className={className}>
                {showMoreDaysButton && (
                  <Button
                    id='moreDaysButton'
                    size='small'
                    variant='contained'
                    color='primary'
                    onClick={handleAddDays}>
                    {t("time.moreDays")}
                  </Button>
                )}
                {showRevertButton && (
                  <Button
                    id='revertDaysButton'
                    size='small'
                    variant='contained'
                    color='secondary'
                    onClick={handleRevertDays}>
                    {t("time.revert")}
                  </Button>
                )}
                <Button
                  id='pickDateButton'
                  size='small'
                  variant='contained'
                  color='warning'
                  onClick={handleToggleCalendar}>
                  {showWeek ? t("time.showWeek") : t("time.pickDate")}
                </Button>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      );
    }
  };
  return <TableComponent />;
};

export default TimeTable;
