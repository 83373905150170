import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export const MapInfo = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isLgScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const [expanded, setExpanded] = React.useState<string | false>(
    isLgScreen ? "panel1" : false
  );

  const tb = (key: string) => {
    const translation = t(key);
    return translation.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Grid container padding={2} flexDirection={"column"}>
      <Grid item textAlign={"center"}>
        <Typography variant='h5'>{t("map.directions")}</Typography>
      </Grid>
      <Grid item width={"100%"}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}>
          <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
            <Typography>
              <LocationOnIcon />
              {t("map.bcn")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign={"left"}>{tb("map.bcnText")}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}>
          <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
            <Typography>
              <LocationOnIcon />
              {t("map.tar")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign={"left"}>{tb("map.tarText")}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}>
          <AccordionSummary aria-controls='panel3d-content' id='panel3d-header'>
            <Typography>
              <LocationOnIcon />
              {t("map.int")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography textAlign={"left"}>{tb("map.intText")}</Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};
