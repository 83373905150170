import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const PP = () => {
  const { t: translate } = useTranslation();

  const t = (key: string) => {
    const translation = translate(key);
    return translation !== key
      ? translation.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))
      : null;
  };

  const renderPrivacyPolicyContent = () => {
    const name = "privacyPolicy.content.";
    let start = 1;
    const startAdd = ".";
    const content = [];

    while (true) {
      let i = 1;
      let key = `${name}${start}${startAdd}${i}`;
      let translation = t(key);

      if (translation) {
        // Add title for the current batch
        const titleKey = `${name}${start}.title`;
        const titleTranslation = t(titleKey);

        if (titleTranslation) {
          content.push(
            <Typography variant='h6' fontWeight={"bold"} key={titleKey}>
              {titleTranslation}
            </Typography>
          );
        }
      }

      while (translation) {
        content.push(
          <React.Fragment key={key}>
            <Typography padding={1}>{translation}</Typography>
            <Divider variant='middle' />
          </React.Fragment>
        );

        i++;
        key = `${name}${start}${startAdd}${i}`;
        translation = t(key);
      }

      if (!translation && i === 1) {
        // No translations found for the current batch and no more batches remaining
        break;
      }

      start++;
    }

    return content;
  };

  return <Grid>{renderPrivacyPolicyContent()}</Grid>;
};
