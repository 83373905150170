import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { prices } from "../data/data";
import WarningIcon from "@mui/icons-material/Warning";

export const SchoolInfo = () => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12} bgcolor={"pink"} padding={1}>
        <Typography variant='body2'>
          <WarningIcon color='warning' sx={{ width: "20px", height: "auto" }} />{" "}
          {t("school.notice")}{" "}
          <WarningIcon color='warning' sx={{ width: "20px", height: "auto" }} />
        </Typography>
      </Grid>

      <Grid
        item
        md={4}
        padding={1}
        sx={{ display: { xs: "none", md: "block", lg: "block" } }}>
        <img
          src={require("../images/garden.jpg")}
          alt='garden'
          className='itemImg'
        />
      </Grid>

      <Grid item md={8} padding={2} alignSelf={"center"}>
        <Typography variant='h4'>{t("school.startTitle")}</Typography>
        <Typography>{t("school.startDesc")}</Typography>
      </Grid>

      <Grid
        item
        md={4}
        padding={1}
        sx={{ display: { xs: "block", md: "none", lg: "none" } }}>
        <img
          src={require("../images/garden.jpg")}
          alt='garden'
          className='itemImg'
        />
      </Grid>

      <Grid item xs={12} bgcolor={"#4caf50"} padding={1}>
        <Typography variant='h4'>{t("school.planTitle")}</Typography>
      </Grid>

      <Grid item md={8} padding={2} alignSelf={"center"}>
        <Typography variant='h6' bgcolor={"lightblue"}>
          {t("school.visitTitle")}
        </Typography>
        <Typography>{t("school.visitDesc")}</Typography>
      </Grid>
      <Grid item md={4} padding={1}>
        <img
          src={require("../images/sign.jpg")}
          alt='sign'
          className='itemImg'
        />
      </Grid>
      <Grid item xs={12} bgcolor={"#4caf50"} padding={1}>
        <Typography variant='h4'>{t("school.areasTitle")}</Typography>
      </Grid>
      <Grid
        item
        md={4}
        padding={1}
        sx={{ display: { xs: "none", md: "block", lg: "block" } }}>
        <img
          src={require("../images/birds.jpg")}
          alt='birds'
          className='itemImg'
        />
      </Grid>
      <Grid item md={8} padding={2} alignSelf={"center"}>
        <Typography variant='h6' bgcolor={"lightblue"}>
          {t("school.firstAreaTitle")}
        </Typography>
        <Typography>{t("school.firstAreaDesc")}</Typography>
        <Typography fontWeight={"bold"}>60-90 min</Typography>
      </Grid>
      <Grid
        item
        md={4}
        padding={1}
        sx={{ display: { xs: "block", md: "none", lg: "none" } }}>
        <img
          src={require("../images/birds.jpg")}
          alt='birds'
          className='itemImg'
        />
      </Grid>
      <Grid item md={8} padding={2} alignSelf={"center"}>
        <Typography variant='h6' bgcolor={"lightblue"}>
          {t("school.secondAreaTitle")}
        </Typography>
        <Typography>{t("school.secondAreaDesc")}</Typography>
        <Typography fontWeight={"bold"}>30-40 min</Typography>
      </Grid>
      <Grid
        item
        md={4}
        padding={1}
        sx={{ display: { xs: "block", md: "none", lg: "none" } }}>
        <img
          src={require("../images/pond.jpg")}
          alt='pond'
          className='itemImg'
        />
      </Grid>
      <Grid
        item
        md={4}
        padding={1}
        sx={{ display: { xs: "none", md: "block", lg: "block" } }}>
        <img
          src={require("../images/pond.jpg")}
          alt='pond'
          className='itemImg'
        />
      </Grid>

      <Grid
        item
        md={4}
        padding={1}
        sx={{ display: { xs: "none", md: "block", lg: "block" } }}>
        <img
          src={require("../images/forest.jpg")}
          alt='forest'
          className='itemImg'
        />
      </Grid>
      <Grid item md={8} padding={2} alignSelf={"center"}>
        <Typography variant='h6' bgcolor={"lightblue"}>
          {t("school.thirdAreaTitle")}
        </Typography>
        <Typography>{t("school.thirdAreaDesc")}</Typography>
        <Typography fontWeight={"bold"}>45-60 min</Typography>
      </Grid>

      <Grid
        item
        md={4}
        padding={1}
        sx={{ display: { xs: "block", md: "none", lg: "none" } }}>
        <img
          src={require("../images/forest.jpg")}
          alt='forest'
          className='itemImg'
        />
      </Grid>

      <Grid item md={8} padding={2} alignSelf={"center"}>
        <Typography variant='h6' bgcolor={"lightblue"}>
          {t("school.forthAreaTitle")}
        </Typography>
        <Typography>{t("school.forthAreaDesc")}</Typography>
        <Typography fontWeight={"bold"}>30-40 min</Typography>
      </Grid>
      <Grid item md={4} padding={1}>
        <img
          src={require("../images/golf.jpg")}
          alt='pond'
          className='itemImg'
        />
      </Grid>

      <Grid
        item
        md={4}
        padding={1}
        sx={{ display: { xs: "none", md: "block", lg: "block" } }}>
        <img
          src={require("../images/picnic.jpg")}
          alt='picnic'
          className='itemImg'
        />
      </Grid>
      <Grid item md={8} padding={2} alignSelf={"center"}>
        <Typography variant='h6' bgcolor={"lightblue"}>
          {t("school.parkingAreaTitle")}
        </Typography>
        <Typography>{t("school.parkingAreaDesc")}</Typography>
      </Grid>

      <Grid
        item
        md={4}
        padding={1}
        sx={{ display: { xs: "block", md: "none", lg: "none" } }}>
        <img
          src={require("../images/picnic.jpg")}
          alt='picnic'
          className='itemImg'
        />
      </Grid>

      <Grid item xs={12} bgcolor={"#4caf50"} padding={1}>
        <Typography variant='h4'>{t("school.infoTitle")}</Typography>
      </Grid>

      <Grid
        item
        md={4}
        padding={1}
        sx={{ display: { xs: "none", md: "block", lg: "block" } }}>
        <img
          src={require("../images/birdSign.jpg")}
          alt='birdSign'
          className='itemImg'
        />
      </Grid>
      <Grid item md={8} padding={2} alignSelf={"center"}>
        <Typography variant='h6' bgcolor={"lightblue"} padding={0.5}>
          {t("school.reserveTitle")}
        </Typography>
        <Typography>
          {t("school.reserveDesc.info")}
          <Typography padding={1} textAlign={"left"}>
            ● {t("school.reserveDesc.details1")}
          </Typography>
          <Typography padding={1} textAlign={"left"}>
            ● {t("school.reserveDesc.details2")}
          </Typography>
          <Typography padding={1} textAlign={"left"}>
            ● {t("school.reserveDesc.details3")}
          </Typography>
          <Typography padding={1} textAlign={"left"}>
            ● {t("school.reserveDesc.details4")}
          </Typography>
          <Typography padding={1} textAlign={"left"}>
            ● {t("school.reserveDesc.details5")}
          </Typography>
          <Typography padding={1} textAlign={"left"}>
            ● {t("school.reserveDesc.details6")}
          </Typography>
          <Typography>{t("school.reserveDesc.endInfo")}</Typography>
        </Typography>
      </Grid>

      <Grid item md={8} padding={2} alignSelf={"center"}>
        <Typography variant='h6' bgcolor={"lightblue"}>
          {t("school.payTitle")}
        </Typography>
        <Typography>{t("school.payDesc.info")}</Typography>
        <Typography padding={1} textAlign={"left"}>
          ● 5-19 {t("school.payDesc.people")} ={" "}
          {(prices.kids - prices.kids * 0.1).toFixed(2)}€
        </Typography>
        <Typography padding={1} textAlign={"left"}>
          ● 20-29 {t("school.payDesc.people")} ={" "}
          {(prices.kids - prices.kids * 0.15).toFixed(2)}€
        </Typography>
        <Typography padding={1} textAlign={"left"}>
          ● 30+ {t("school.payDesc.people")} ={" "}
          {(prices.kids - prices.kids * 0.2).toFixed(2)}€
        </Typography>
        <Typography>{t("school.payDesc.payType")}</Typography>
        <Typography padding={1} textAlign={"left"}>
          ● {t("school.payDesc.transfer")}
        </Typography>
        <Typography padding={1} textAlign={"left"}>
          ● Bizum
        </Typography>
        <Typography padding={1} textAlign={"left"}>
          ● {t("school.payDesc.Portal")}
        </Typography>
      </Grid>
      <Grid
        item
        md={4}
        padding={1}
        sx={{ display: { xs: "none", md: "block", lg: "block" } }}>
        <img
          src={require("../images/budgie.jpg")}
          alt='budgie'
          className='itemImg'
        />
      </Grid>

      <Grid
        item
        md={4}
        padding={1}
        sx={{ display: { xs: "none", md: "block", lg: "block" } }}>
        <img
          src={require("../images/birdArea.jpg")}
          alt='birdArea'
          className='itemImg'
        />
      </Grid>

      <Grid item md={8} padding={2} alignSelf={"center"}>
        <Typography variant='h6' bgcolor={"lightblue"}>
          {t("school.condTitle")}
        </Typography>
        <Typography>{t("school.condDesc")}</Typography>
      </Grid>
      <Grid
        item
        md={4}
        padding={1}
        sx={{ display: { xs: "block", md: "none", lg: "none" } }}>
        <img
          src={require("../images/birdArea.jpg")}
          alt='birdArea'
          className='itemImg'
        />
      </Grid>
    </Grid>
  );
};
