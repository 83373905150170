import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { Footer } from "../components/Bars/Footer";
import { Information } from "../components/Information";
import Map from "../components/Map";
import { NavBarTop } from "../components/Bars/NavBarTop";
import { Prices } from "../components/Prices";
import TimeTable from "../components/TimeTable";
import Weather from "../components/Weather";
import { WhatSpot } from "../components/Carousel/WhatSpot";
import { useTranslation } from "react-i18next";

export const MainSimplePage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = t("parkName");
  });
  return (
    <Grid>
      <Grid container>
        <NavBarTop />
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={6} className='boxGreen'>
          <Information />
        </Grid>
        <Grid item md={12} lg={6} className='boxGreen'>
          <Map />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Prices party={false} />
        </Grid>
        <Grid container xs={12} lg={4}>
          <Grid item xs={12}>
            <Weather />
          </Grid>
          <Grid item xs={12}>
            <TimeTable tour={true} calendar={false} holiday={false} />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <TimeTable tour={false} calendar={false} holiday={false} />
        </Grid>
        <Grid item xs={12}>
          <WhatSpot />
        </Grid>
      </Grid>
      <Grid container>
        <Footer />
      </Grid>
    </Grid>
  );
};
