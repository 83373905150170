import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Link,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { prices } from "../data/data";

import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import LinkIcon from "@mui/icons-material/Link";

interface PassCardProps {
  image: string;
  passType: string;
  passPrice: number;
  passLength: number;
}

const PassCard: React.FC<PassCardProps> = ({
  image,
  passType,
  passPrice,
  passLength,
}) => {
  const { t } = useTranslation();

  return (
    <Grid item>
      <Card>
        <CardContent>
          <Typography>
            <span style={{ fontWeight: "bold" }}>{t(passType)}</span>
          </Typography>
          <Typography>
            <span style={{ fontWeight: "bold" }}>{t("multipass.price")}:</span>{" "}
            {passPrice.toFixed(2)}€
          </Typography>
          <Typography>
            <span style={{ fontWeight: "bold" }}>
              {t("multipass.duration")}:
            </span>{" "}
            {passLength > 6 ? (
              <span style={{ color: "green" }}>
                {passLength} {t("multipass.month")}
              </span>
            ) : (
              <span style={{ color: "orangered" }}>
                {passLength} {t("multipass.month")}
              </span>
            )}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export const YearPassInfo = () => {
  const { t } = useTranslation();
  return (
    <Grid container justifyContent={"center"}>
      <Grid container padding={2}>
        <Typography>{t("multipass.infoStart")}</Typography>
        <List>
          <Grid item xs={12}>
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    textAlign={"center"}
                    variant='h5'
                    bgcolor={"lightblue"}
                    padding={1}>
                    {t("multipass.include.title")}
                  </Typography>
                }
                secondary={
                  <Typography
                    component={"div"}
                    className={"css-ty9jyy-MuiTypography-root"}>
                    <ul style={{ listStyleType: "disc" }}>
                      <li>
                        <span style={{ fontWeight: "bold" }}>
                          {t("multipass.include.cardTitle")}:
                        </span>{" "}
                        {t("multipass.include.cardInfo")}
                      </li>
                      <li>
                        <span style={{ fontWeight: "bold" }}>
                          {t("multipass.include.visitsTitle")}:
                        </span>{" "}
                        {t("multipass.include.visitsInfo")}
                      </li>
                      <li>
                        <span style={{ fontWeight: "bold" }}>
                          {t("multipass.include.benefitTitle")}:
                        </span>{" "}
                        {t("multipass.include.benefitInfo")}
                      </li>
                      <li>
                        <span style={{ fontWeight: "bold" }}>
                          {t("multipass.include.supportTitle")}:
                        </span>{" "}
                        {t("multipass.include.supportInfo")}
                      </li>
                    </ul>
                  </Typography>
                }
              />
            </ListItem>
          </Grid>
        </List>
        <Grid container padding={2} spacing={2} className={"boxBlue"}>
          <Grid item sm={12}>
            <Typography
              padding={1}
              bgcolor={"lightgoldenrodyellow"}
              variant='h5'>
              {t("multipass.order.title")}
            </Typography>
            <Divider>
              <Typography>{t("multipass.step")}</Typography>
              <LooksOneIcon />
            </Divider>
            <Typography>{t("multipass.order.choose")}</Typography>
            <Typography variant='body2'>
              {t("multipass.order.chooseSub")}
            </Typography>
            <Grid container spacing={2} justifyContent={"space-around"}>
              <PassCard
                image={require("../images/pase_anual.jpg")}
                passType={"prices.adult"}
                passPrice={prices.adultYearPass}
                passLength={12}
              />
              <PassCard
                image={require("../images/pase_anual.jpg")}
                passType={"prices.kids"}
                passPrice={prices.kidsYearPass}
                passLength={12}
              />
              <PassCard
                image={require("../images/pase_anual.jpg")}
                passType={"prices.retired"}
                passPrice={prices.retiredYearPass}
                passLength={12}
              />
            </Grid>
            <Grid
              container
              paddingTop={2}
              spacing={2}
              justifyContent={"space-around"}>
              <PassCard
                image={require("../images/pase_anual.jpg")}
                passType={"prices.adult"}
                passPrice={prices.adultSeasonalPass}
                passLength={3}
              />
              <PassCard
                image={require("../images/pase_anual.jpg")}
                passType={"prices.kids"}
                passPrice={prices.kidsSeasonalPass}
                passLength={3}
              />
              <PassCard
                image={require("../images/pase_anual.jpg")}
                passType={"prices.retired"}
                passPrice={prices.retiredSeasonalPass}
                passLength={3}
              />
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Divider>
              <Typography>{t("multipass.step")}</Typography>
              <LooksTwoIcon />
            </Divider>

            <ul>
              <li>
                {t("multipass.order.listOne")}
                <Typography variant='body2' color={"red"}>
                  {t("multipass.order.listOneSub")}
                </Typography>
              </li>
              <li>{t("multipass.order.listTwo")}</li>
              <li>{t("multipass.order.listThree")}</li>
            </ul>
          </Grid>
          <Grid item sm={12}>
            <Divider>
              <Typography>{t("multipass.step")}</Typography>
              <Looks3Icon />
            </Divider>
            <Typography>
              <strong>{t("multipass.order.visitCollect")}</strong>
            </Typography>
            <Typography>{t("multipass.order.visitEnjoy")}</Typography>
          </Grid>
          <Grid item sm={12}>
            <Divider>
              <CardGiftcardIcon />
            </Divider>
            <Card>
              <CardMedia
                sx={{ height: "25vh" }}
                component='img'
                image={require("../images/pase_anual.jpg")}
              />
              <CardContent>
                <Typography>{t("multipass.order.secure")}</Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center" }}>
                <Link
                  aria-label='multipass-link'
                  href='https://multipass-eljardidelsocells.square.site/product/multipass-3/85'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <Button variant='contained' size='large'>
                    <LinkIcon color='secondary' />
                    <Typography color={"secondary"}>
                      {t("multipass.order.buySeasonal")}
                    </Typography>
                  </Button>
                </Link>
              </CardActions>
              <CardActions sx={{ justifyContent: "center" }}>
                <Link
                  aria-label='multipass-link'
                  href='https://multipass-eljardidelsocells.square.site/product/multipass-12/18'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <Button variant='contained' size='large'>
                    <LinkIcon color='secondary' />
                    <Typography color={"secondary"}>
                      {t("multipass.order.buyYear")}
                    </Typography>
                  </Button>
                </Link>
              </CardActions>
            </Card>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
